import { useState } from "react";
import { Link } from "react-router-dom";

/* ASSETS */
import Logo from "assets/images/scaletrade-logo.png";

/* MATERIAL TAILWIND COMPONENTS */
import { Button, Collapse } from "@material-tailwind/react";
import AccountCreation from "components/others/accountCreation";

/* ICONS */
import { HiBars2 } from "react-icons/hi2";

const WebsiteHeader = () => {
  const [currentTab, setCurrentTab] = useState("login");
  const [currentState, setCurrentState] = useState(false);
  const [mobileHeader, setMobileHeader] = useState(false);

  return (
    <header className="bg-header py-3 px-3 xl:px-5 lg:px-5">
      <div className="container mx-auto">
        <div className="flex items-center justify-between">
          {/* LOGO */}
          <Link to="/">
            <img
              src={Logo}
              className="h-10 w-auto object-contain"
              alt="ScaleTrade"
            />
          </Link>

          {/* NAVIGATION OPTIONS */}
          <div className="hidden xl:flex lg:flex items-center gap-10">
            <Link
              to="/pricing"
              className="block text-base font-redhat-semibold text-white hover:text-logoGreen transition-all duration-300 ease-in-out"
            >
              Pricing
            </Link>
            <a
              href="https://resources.scaletrade.ai/about-scaletrade/meet-the-team"
              target="_blank"
              rel="noreferrer"
              className="block text-base font-redhat-semibold text-white hover:text-logoGreen transition-all duration-300 ease-in-out"
            >
              Docs
            </a>
            <Link
              to="/about"
              className="block text-base font-redhat-semibold text-white hover:text-logoGreen transition-all duration-300 ease-in-out"
            >
              About
            </Link>
            <Button
              onClick={() => {
                setCurrentTab("login");
                setCurrentState(true);
              }}
              className="shadow-none hover:shadow-none bg-transparent p-0 normal-case font-redhat-semibold text-base transition-all duration-300 hover:text-logoGreen"
            >
              Login
            </Button>
            <Button
              onClick={() => {
                setCurrentTab("register");
                setCurrentState(true);
              }}
              className="shadow-none hover:shadow-none bg-transparent hover:bg-white hover:bg-opacity-10 rounded-md px-4 py-2 border border-white border-opacity-50 normal-case font-redhat-semibold text-base transition-all duration-300"
            >
              Create account
            </Button>
          </div>

          <Button
            className="block xl:hidden lg:hidden shadow-none hover:shadow-none p-2 rounded-full bg-transparent border border-white"
            onClick={() => setMobileHeader(!mobileHeader)}
          >
            <HiBars2 className="w-5 h-5 text-white" />
          </Button>
        </div>
      </div>
      <AccountCreation
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        currentState={currentState}
        setCurrentState={setCurrentState}
      />

      <Collapse open={mobileHeader}>
        <div className="p-5">
          <Link
            to="/pricing"
            onClick={() => setMobileHeader(!mobileHeader)}
            className="block text-base font-redhat-semibold text-white hover:text-logoGreen transition-all duration-300 ease-in-out"
          >
            Pricing
          </Link>
          <a
            href="https://resources.scaletrade.ai/about-scaletrade/meet-the-team"
            target="_blank"
            rel="noreferrer"
            className="mt-3 block text-base font-redhat-semibold text-white hover:text-logoGreen transition-all duration-300 ease-in-out"
          >
            Docs
          </a>
          <Link
            to="/about"
            onClick={() => setMobileHeader(!mobileHeader)}
            className="mt-3 block text-base font-redhat-semibold text-white hover:text-logoGreen transition-all duration-300 ease-in-out"
          >
            About
          </Link>
          <Button
            onClick={() => {
              setCurrentTab("login");
              setCurrentState(true);
              setMobileHeader(!mobileHeader);
            }}
            className="mt-3 block shadow-none hover:shadow-none bg-transparent p-0 normal-case font-redhat-semibold text-base transition-all duration-300 hover:text-logoGreen"
          >
            Login
          </Button>
          <Button
            onClick={() => {
              setCurrentTab("register");
              setCurrentState(true);
              setMobileHeader(!mobileHeader);
            }}
            className="mt-3 block shadow-none hover:shadow-none bg-transparent hover:bg-white hover:bg-opacity-10 rounded-md px-4 py-2 border border-white border-opacity-50 normal-case font-redhat-semibold text-base transition-all duration-300"
          >
            Create account
          </Button>
        </div>
      </Collapse>
    </header>
  );
};

export default WebsiteHeader;
