const SERVER_URL_DEV = "https://terminal-backend.dev.skilltrade.ai/user";
const SERVER_URL_PROD = "https://terminal-backend.prod.skilltrade.ai/user";
// const SERVER_URL = "http://localhost:8080/user";
// const API_SERVER_URL_DEV = "https://scale-link.staging.skilltrade.ai/rest/v2";
const API_SERVER_URL_DEV = "https://scale-link.dev.skilltrade.ai/rest/v2";
const API_SERVER_URL_PROD = "https://scale-link.prod.skilltrade.ai/rest/v2";
const PAYMENT_LINK_FREE_TRIAL = "https://buy.stripe.com/14keVXbkjayscyk8wF";
const PAYMENT_LINK = "https://buy.stripe.com/9AQ3dfdsr9uodCobIS";
// https://buy.stripe.com/test_7sI2aQh0E1BSd1ufZ0
const environment = "PROD";
// const key = process.env.REACT_APP_API_KEY
const key = "R!mP!3k_Q6Mw2ykgYK7XYXBpMo7Cv!T2D4PKBhVPqhAkUpsMj2";
const encryptionKey = "HnPC2ueFwKdcFoaLq7xhkDm3chGVfsFNdXN8a6PNFX7pQ3";
const SET_FORMAT = (amount) => {
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return formatter.format(amount);
};

module.exports = {
  SERVER_URL: environment === "DEV" ? SERVER_URL_DEV : SERVER_URL_PROD,
  API_SERVER_URL:
    environment === "DEV" ? API_SERVER_URL_DEV : API_SERVER_URL_PROD,
  PAYMENT_LINK,
  PAYMENT_LINK_FREE_TRIAL,
  environment,
  key,
  externalEncryptionKey: encryptionKey,
  SET_FORMAT,
};
