/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from "@material-tailwind/react";

/* REACT TOASTIFY  */
import { toast } from "react-toastify";

/* IMPORT ROUTER DOM FOR LINK */
import { useNavigate } from "react-router-dom";

/* REDUX IMPORTS */
import { useDispatch, useSelector } from "react-redux";
import userAction from "redux/actions/user.action";

/* API IMPORTS */
import {
  authLogout,
  profileUpdateConnectedAccount,
  removeUserAccountBrokerageApi,
  updateSubscription,
} from "service/api";

/* PACKAGES */
import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import HelperGetData from "components/others/helpers";
import { PAYMENT_LINK, PAYMENT_LINK_FREE_TRIAL } from "config";

const AccountPage = () => {
  /* INITIALIZE FUNCTIONS */
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userId = useSelector((state) => state?.user?.userId);
  const userDetails = useSelector((state) => state?.user?.user);
  const accountsList = useSelector((state) => state?.user?.allAccounts);
  const [isEmailPresent, setIsEmailPresent] = useState(false);

  /* HANDLE LOGOUT FUNCTION */
  const handleLogout = () => {
    authLogout().then((response) => {
      if (response?.status) {
        toast.warn(response?.message);
        dispatch(userAction.clearData());
        setTimeout(() => {
          navigate("/");
        }, [1500]);
      }
    });
  };

  const handleConnectedAccount = (accountIdTest, type) => {
    if (accountIdTest) {
      if (type === "remove") {
        profileUpdateConnectedAccount({ connectedAccount: "Disconnected" })
          .then((res) => {
            dispatch(userAction.setUser(res?.data));
            dispatch(userAction.setAccountId(null));

            removeUserAccountBrokerageApi(userId, accountIdTest)
              .then((res) => {
                toast.success(res?.message);
                setTimeout(() => {
                  window.location.reload();
                }, 1500);
              })
              .catch((err) => console.log("Error: ", err))
              .finally(() => {
                dispatch(userAction.setApiStatus(true));
              });
            // dispatch(
            //   userAction.setAccountId("c9ffee7f-ea04-4f5e-85b7-338283a63d90")
            // );
            // dispatch(userAction.setUserId("v2testing2"));
          })
          .catch((err) => console.log("Error: ", err))
          .finally(() => {
            dispatch(userAction.setApiStatus(true));
          });
      } else {
        profileUpdateConnectedAccount({ connectedAccount: accountIdTest })
          .then((res) => {
            dispatch(userAction.setUser(res?.data));

            // dispatch(userAction.setAccountId("c9ffee7f-ea04-4f5e-85b7-338283a63d90"));
            // dispatch(userAction.setUserId("v2testing2"));
            dispatch(userAction.setAccountId(accountIdTest));
            dispatch(userAction.setUserId(res?.data?._id));
          })
          .catch((err) => console.log("ERR", err))
          .finally(() => {
            dispatch(userAction.setApiStatus(true));
          });
      }
    }
  };

  const updateSubscriptionsFunction = () => {
    updateSubscription()
      .then((res) => {
        if (res?.status) {
          window.location.replace(res?.data?.url);
        } else {
          toast.warn(res?.message);
        }
      })
      .catch((e) => {
        console.log("Error: ", e);
      });
  };

  useEffect(() => {
    if (!userDetails) {
      toast.warn("Please sign in to ScaleTrade Account");
      navigate("/");
    } else {
      dispatch(userAction.setApiStatus(true));
    }
  }, []);

  return (
    <section className="px-3 xl:px-10 lg:px-10 py-3 xl:py-7 lg:py-7">
      {/* PAGE TITLE */}
      <Helmet>
        <title>Create your trade | ScaleTrade</title>
      </Helmet>
      <HelperGetData />
      <div className="container mx-auto">
        <div className="w-full xl:w-4/5 lg:w-4/5 mx-auto">
          <div className="bg-white rounded-xl border-2 border-logoGreen py-5 xl:py-20 lg:py-20 px-5 xl:px-0 lg:px-0">
            <div className="w-full xl:w-1/2 lg:w-1/2 mx-auto">
              {/* NAME AND TIME */}
              <div className="mt-0">
                <h1 className="text-2xl xl:text-3xl lg:text-3xl font-redhat-extrabold text-black text-center">
                  {userDetails?.firstName} {userDetails?.lastName}
                </h1>
                <h2 className="text-lg xl:text-xl lg:text-xl font-redhat-semibold text-greyText text-center">
                  ScaleTrade member since{" "}
                  {new Date(userDetails?.createdAt).toLocaleString("default", {
                    month: "short",
                  })}
                  '
                  {new Date(userDetails?.createdAt).toLocaleDateString("en", {
                    year: "2-digit",
                  })}
                </h2>
              </div>

              {/* PERSONAL DETAILS */}
              <div className="py-5 xl:py-10 lg:py-10 border-b border-inputBox">
                {/* EMAIL */}
                <div className="mt-0">
                  <div className="flex items-center justify-between">
                    <p className="text-base xl:text-lg lg:text-lg font-redhat-bold text-black flex-shrink-0">
                      Email
                    </p>
                    <p className="text-base xl:text-lg lg:text-lg font-redhat-regular text-black flex-shrink-0">
                      {userDetails?.email}
                    </p>
                  </div>
                </div>

                {/* PASSWORD */}
                <div className="mt-5">
                  <div className="flex items-center justify-between">
                    <p className="text-base xl:text-lg lg:text-lg font-redhat-bold text-black flex-shrink-0">
                      Password
                    </p>
                    <p className="text-base xl:text-lg lg:text-lg font-redhat-regular text-black flex-shrink-0">
                      --------
                    </p>
                  </div>
                </div>

                {/* COUNTRY */}
                <div className="mt-5">
                  <div className="flex items-center justify-between">
                    <p className="text-base xl:text-lg lg:text-lg font-redhat-bold text-black flex-shrink-0">
                      Country
                    </p>
                    <p className="text-base xl:text-lg lg:text-lg font-redhat-regular text-black flex-shrink-0">
                      {userDetails?.country}
                    </p>
                  </div>
                </div>

                {/* ADDRESS */}
                {/* <div className="mt-5">
                  <div className="flex items-center justify-between">
                    <p className="text-base xl:text-lg lg:text-lg font-redhat-bold text-black flex-shrink-0">
                      Address
                    </p>
                    <p className="text-base xl:text-lg lg:text-lg font-redhat-regular text-black flex-shrink-0">
                      {userDetails?.address}, {userDetails?.city},{" "}
                      {userDetails?.state}
                    </p>
                  </div>
                </div> */}

                {/* ZIPCODE */}
                {/* <div className="mt-5">
                  <div className="flex items-center justify-between">
                    <p className="text-base xl:text-lg lg:text-lg font-redhat-bold text-black flex-shrink-0">
                      Zip Code
                    </p>
                    <p className="text-base xl:text-lg lg:text-lg font-redhat-regular text-black flex-shrink-0">
                      {userDetails?.zipCode}
                    </p>
                  </div>
                </div> */}

                {/* INVESTMENT EXPERIENCE */}
                <div className="mt-5 hidden">
                  <div className="flex items-center justify-between">
                    <p className="text-base xl:text-lg lg:text-lg font-redhat-bold text-black flex-shrink-0">
                      Investment Experience
                    </p>
                    <p className="text-base xl:text-lg lg:text-lg font-redhat-regular text-black flex-shrink-0">
                      {userDetails?.investingExp}
                    </p>
                  </div>
                </div>

                {/* NUMBER OF ACCOUNTS */}
                <div className="mt-5 hidden">
                  <div className="flex items-center justify-between">
                    <p className="text-base xl:text-lg lg:text-lg font-redhat-bold text-black flex-shrink-0">
                      No. of Accounts
                    </p>
                    <p className="text-base xl:text-lg lg:text-lg font-redhat-regular text-black flex-shrink-0">
                      {userDetails?.numberOfAccounts}
                    </p>
                  </div>
                </div>

                {userDetails?.company === undefined ? (
                  <>
                    {/* HOW DID YOU HEAR ABOUT US */}
                    <div className="mt-5 hidden">
                      <div className="flex items-center justify-between">
                        <p className="text-base xl:text-lg lg:text-lg font-redhat-bold text-black flex-shrink-0">
                          How did you hear about us?
                        </p>
                        <p className="text-base xl:text-lg lg:text-lg font-redhat-regular text-black flex-shrink-0">
                          {userDetails?.hearAboutUs}
                        </p>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {/* COMPANY */}
                    <div className="mt-5 hidden">
                      <div className="flex items-center justify-between">
                        <p className="text-base xl:text-lg lg:text-lg font-redhat-bold text-black flex-shrink-0">
                          Company
                        </p>
                        <p className="text-base xl:text-lg lg:text-lg font-redhat-regular text-black flex-shrink-0">
                          {userDetails?.company}
                        </p>
                      </div>
                    </div>
                    {/* JOB TITLE */}
                    <div className="mt-5 hidden">
                      <div className="flex items-center justify-between">
                        <p className="text-base xl:text-lg lg:text-lg font-redhat-bold text-black flex-shrink-0">
                          Company
                        </p>
                        <p className="text-base xl:text-lg lg:text-lg font-redhat-regular text-black flex-shrink-0">
                          {userDetails?.isEmployee?.jobTitle
                            ? userDetails?.isEmployee?.jobTitle
                            : "-"}
                        </p>
                      </div>
                    </div>
                  </>
                )}
              </div>

              {/* BROKERAGES */}
              <div className="py-5 xl:py-10 lg:py-10 border-b border-inputBox">
                <h5 className="text-xl xl:text-2xl lg:text-2xl font-redhat-bold text-black">
                  Brokerages
                </h5>
                {accountsList?.accounts?.map((element, index) => {
                  return (
                    <div className="mt-5">
                      <div className="flex items-center justify-between">
                        <p className="text-base xl:text-lg lg:text-lg font-redhat-regular text-black flex-shrink-0">
                          {element?.brokerage +
                            " x" +
                            element?.accountId?.slice(-4)}
                        </p>
                        <Button
                          onClick={() => {
                            handleConnectedAccount(
                              element?.accountId,
                              "remove"
                            );
                          }}
                          className="w-fit xl:w-[15vh] lg:w-[15vh] shadow-none hover:shadow-none bg-red-500 bg-opacity-20 hover:bg-opacity-30 rounded-md normal-case font-redhat-bold text-red-500 text-sm xl:text-base lg:text-base transition-all duration-300 ease-in-out"
                        >
                          Disconnect
                        </Button>
                        {/* {element?.accountId ===
                          userDetails?.connectedAccount ? (
                          <Button
                            onClick={() => {
                              handleConnectedAccount(
                                element?.accountId,
                                "remove"
                              );
                            }}
                            className="w-fit xl:w-[15vh] lg:w-[15vh] shadow-none hover:shadow-none bg-red-500 bg-opacity-20 hover:bg-opacity-30 rounded-md normal-case font-redhat-bold text-red-500 text-sm xl:text-base lg:text-base transition-all duration-300 ease-in-out"
                          >
                            Disconnect
                          </Button>
                        ) : (
                          <Button
                            onClick={() => {
                              handleConnectedAccount(element?.accountId, "add");
                            }}
                            className="w-fit xl:w-[15vh] lg:w-[15vh] shadow-none hover:shadow-none bg-[#3DBC73] hover:bg-opacity-80 rounded-md normal-case font-redhat-bold text-[#0E331E] text-sm xl:text-base lg:text-base transition-all duration-300 ease-in-out"
                          >
                            Connect
                          </Button>
                        )} */}
                      </div>
                    </div>
                  );
                })}

                {/* <div className="mt-5">
                  <div className="flex items-center justify-between">
                    <p className="text-lg font-redhat-regular text-black flex-shrink-0">
                      TD Ameritrade x5914
                    </p>
                   
                  </div>
                </div> */}
              </div>

              {/* EMAIL PREFERENCES */}
              <div className="py-5 xl:py-10 lg:py-10 border-b border-inputBox">
                <h5 className="text-xl xl:text-2xl lg:text-2xl font-redhat-bold text-black">
                  Email Preferences
                </h5>
                <div className="mt-5">
                  <div className="block xl:flex lg:flex items-center justify-between">
                    <p className="text-base xl:text-lg lg:text-lg font-redhat-regular text-black flex-shrink-0">
                      Receive notifications about my account
                    </p>
                    <Button className="w-full xl:w-fit lg:w-fit mt-3 xl:mt-0 lg:mt-0 shadow-none hover:shadow-none bg-greyBorder bg-opacity-50 hover:bg-opacity-80 rounded-md normal-case font-redhat-bold text-greyText text-base transition-all duration-300 ease-in-out">
                      Unsubscribe
                    </Button>
                  </div>
                </div>
              </div>

              {/* SUBSCRIPTION AND BILLING */}
              <div className="py-5 xl:py-10 lg:py-10 border-b border-inputBox">
                <h5 className="text-xl xl:text-2xl lg:text-2xl font-redhat-bold text-black">
                  Your Subscription & Billing
                </h5>
                {userDetails?.isSubscribed ? (
                  <div className="block xl:flex lg:flex items-center gap-5 mt-5">
                    <Button
                      onClick={updateSubscriptionsFunction}
                      className="w-full shadow-none hover:shadow-none bg-greyBorder bg-opacity-50 hover:bg-opacity-80 rounded-md normal-case font-redhat-bold text-greyText text-base transition-all duration-300 ease-in-out"
                    >
                      Manage Subscription
                    </Button>
                    {/* <Button className="mt-3 xl:mt-0 lg:mt-0 w-full shadow-none hover:shadow-none bg-greyBorder bg-opacity-50 hover:bg-opacity-80 rounded-md normal-case font-redhat-bold text-greyText text-base transition-all duration-300 ease-in-out">
                    Cancel subscription
                  </Button> */}
                  </div>
                ) : (
                  <div className="block xl:flex lg:flex items-center gap-5 mt-5">
                    <Button
                      onClick={() => {
                        isEmailPresent
                          ? window.location.replace(
                              `${PAYMENT_LINK_FREE_TRIAL}?client_reference_id=${userId}`
                            )
                          : window.location.replace(
                              `${PAYMENT_LINK}?client_reference_id=${userId}`
                            );
                      }}
                      className="w-full shadow-none hover:shadow-none bg-greyBorder bg-opacity-50 hover:bg-opacity-80 rounded-md normal-case font-redhat-bold text-greyText text-base transition-all duration-300 ease-in-out"
                    >
                      Start Subscription
                    </Button>
                  </div>
                )}
              </div>

              {/* LOGOUT OPTION */}
              <div className="py-5 xl:py-10 lg:py-10 border-inputBox">
                <h5 className="text-xl xl:text-2xl lg:text-2xl font-redhat-bold text-black">
                  Sign out
                </h5>
                <div className="mt-5">
                  <Button
                    className="w-full shadow-none hover:shadow-none bg-black hover:bg-opacity-80 rounded-md normal-case font-redhat-bold text-white text-base transition-all duration-300 ease-in-out"
                    onClick={handleLogout}
                  >
                    Sign out of this ScaleTrade account
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AccountPage;
