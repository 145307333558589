/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";

/* PACKAGES */
import { Helmet } from "react-helmet";
import DataTable from "react-data-table-component";
import ReactConfetti from "react-confetti";

/* MATERIAL TAILWIND COMPONENTS */
import {
  Button,
  Accordion,
  AccordionHeader,
  AccordionBody,
  Dialog,
  DialogBody,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from "@material-tailwind/react";

/* COMPONENTS */
import BrokerTile from "components/calculator/broker-tile";

/* ICONS */
import { IoIosArrowDown, IoIosArrowUp, IoMdAdd } from "react-icons/io";
import { IoClose, IoCloseCircleOutline } from "react-icons/io5";
import {
  MdOutlinePlayCircle,
  MdOutlinePauseCircleOutline,
} from "react-icons/md";
import { FaEdit } from "react-icons/fa";

/* ASSETS */
import Alpaca from "assets/images/logos/alpaca.png";
import Robinhood from "assets/images/logos/robinhood.png";

import Tradier from "assets/images/logos/tradier.png";
import TradeStation from "assets/images/logos/trade-station.png";
import Webull from "assets/images/logos/webull.png";

/* DATA */
import CreateAutomationComponent from "components/forms/createAutomation";
import { useSelector } from "react-redux";
import {
  addUserAccountApi,
  brokerageNotFound,
  getAuthorizationLinkApi,
  getUserStrategyAccountApi,
  userStrategySetApi,
  userStrategyStartApi,
  userStrategyStopApi,
} from "service/api";
import moment from "moment";
import brokers from "data/brokers";
import { PAYMENT_LINK, SET_FORMAT } from "config";
import { toast } from "react-toastify";
import EditAutomationComponent from "components/forms/editAutomation";

/* IMAGES */
import { GoKebabHorizontal } from "react-icons/go";
import { stocksList } from "data/investors";
import NewCreateAutomationComponent from "components/forms/newCreateAutomation";
import ChangeAutomation from "components/forms/ChangeAutomation";
import * as yup from "yup";
import { useFormik } from "formik";

const CUSTOM_ANIMATION = {
  mount: { scale: 1 },
  unmount: { scale: 0.9 },
};

const validationSchema = yup.object().shape({
  brokerageName: yup.string().required("Please add Brokerage Name"),
});

const Automate = () => {
  /* FETCH ACCOUNT DETAILS */
  const userId = useSelector((state) => state?.user?.userId);
  const userDetails = useSelector((state) => state?.user?.user);
  const loggedInStatus = useSelector((state) => state?.user?.loggedIn);
  const accountsList = useSelector((state) => state?.user?.allAccounts);
  const selectedAccountDetails = useSelector(
    (state) => state?.user?.selectedAccount
  );
  const allConnectedAccountsList = useSelector(
    (state) => state?.user?.allConnectedAccounts
  );

  const [apiCall, setApiCall] = useState(true);

  /* GLOBAL STATES */
  const [step, setStep] = useState(1);
  const [strategySetup, setStrategySetup] = useState(false);

  const [automationAccountId, setAutomationAccountId] = useState([]);

  const [automationAccountSelected, setAutomationSelected] = useState(null);

  const [automationList, setAutomationList] = useState([]);

  const [automationDialog, setAutomationDialog] = useState(false);
  const [automationStatus, setAutomationStatus] = useState(false);
  const [automationLoading, setAutomationLoading] = useState(false);
  const [brokerDialog, setBrokerDialog] = useState(false);
  const [automationEditDialog, setAutomationEditDialog] = useState(false);
  const [automationEditId, setAutomationEditId] = useState(null);

  const [brokerageNotFoundDialog, setBrokerageNotFoundDialog] = useState(false);
  const [brokerageNotFoundStep, setBrokerageNotFoundStep] = useState(1);
  const [brokerageNotFoundLoading, setBrokerageNotFoundLoading] =
    useState(false);

  const [helperText, setHelperText] = useState(true);

  /* FUNCTIONS */
  const handleGetAuthLink = (slug) => {
    addUserAccountApi(userId)
      .then((res) => {})
      .catch((err) => {
        console.error("handleGetAuthLink", err);
      });
    getAuthorizationLinkApi(userId, slug)
      .then((res) => {
        if (res?.data?.url) {
          window.location.href = res?.data?.url;
        }
      })
      .catch((err) => {
        console.error("handleGetAuthLink", err);
      });
  };
  const getAutomationList = () => {
    let currentValues = automationList;
    automationList?.forEach((element) => {
      getUserStrategyAccountApi(userId, element?.accountId)
        .then((response) => {
          let filtered = currentValues?.filter(
            (el) => el?.accountId !== element?.accountId
          );
          let currentS = currentValues?.find(
            (el) => el?.accountId === element?.accountId
          );
          filtered.push({
            accountId: element?.accountId,
            order: currentS?.order,
            brokerage: currentS?.brokerage,
            accountDetails: currentS?.accountDetails,
            automations: response?.data?.allocations
              ? response?.data?.allocations
              : [],
          });
          currentValues = filtered;
        })
        .catch((err) => {
          console.log("Error:", err);
          let filtered = currentValues?.filter(
            (el) => el?.accountId !== element?.accountId
          );
          let currentS = currentValues?.find(
            (el) => el?.accountId === element?.accountId
          );
          filtered.push({
            accountId: element?.accountId,
            order: currentS?.order,
            brokerage: currentS?.brokerage,
            accountDetails: currentS?.accountDetails,
            automations: [],
          });
          currentValues = filtered;
        })
        .finally(() => {
          setAutomationLoading(false);
          setAutomationList(currentValues.sort((a, b) => a.order - b.order));
        });
    });
  };
  const startAutomationFunction = (automationId) => {
    userStrategyStartApi(userId, { allocations: [automationId] })
      .then((res) => {
        toast.success(res?.message);
      })
      .catch((er) => {
        console.log("Error: ", er);
      })
      .finally(() => {
        window.location.reload();
      });
  };
  const stopAutomationFunction = (automationId) => {
    userStrategyStopApi(userId, { allocations: [automationId] })
      .then((res) => {
        toast.success(res?.message);
      })
      .catch((er) => {
        console.log("Error: ", er);
      })
      .finally(() => {
        window.location.reload();
      });
  };
  const removeAutomation = (removeAutomationId) => {
    userStrategySetApi(userId, { add: [], delete: [removeAutomationId] })
      .then((res) => {
        toast.success(res?.message);
      })
      .catch((er) => {
        console.log("Error: ", er);
      })
      .finally(() => {
        window.location.reload();
      });
  };

  /* FORMIK FORM HANDLER */
  const formik = useFormik({
    initialValues: {
      brokerageName: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setBrokerageNotFoundLoading(true);
      let data = new FormData();
      data.append("brokerageName", values.brokerageName);

      brokerageNotFound(data)
        .then((res) => {
          if (res?.status) {
            toast.success(res?.message);
            formik.resetForm();
            setBrokerageNotFoundStep(2);
          } else {
            toast.error(res?.message);
          }
        })
        .catch((e) => {
          if (typeof e?.response?.data.message === "string") {
            toast.error(e?.response?.data.message);
          } else {
            toast.error(e?.response?.data.message?.details?.[0]?.message);
          }
          console.log("E", e);
        })
        .finally(() => {
          setBrokerageNotFoundLoading(false);
        });
    },
  });

  /* COLUMNS */
  const columns = [
    {
      name: "Automation",
      grow: 1.5,
      selector: (row, index) => {
        return (
          <div className="w-[12vh] py-3">
            <div className="flex justify-center items-center mx-3">
              <img
                src={
                  stocksList?.find((el) => el?.underlying === row?.underlying)
                    ?.image
                }
                className="w-12 h-12 object-cover rounded"
                alt={row?.underlying}
              />
            </div>
            <p className="text-base font-roboto-regular text-black pt-2 whitespace-pre-wrap text-center">
              {
                stocksList?.find((el) => el?.underlying === row?.underlying)
                  ?.name
              }
            </p>
          </div>
        );
      },
    },
    {
      name: "Amount",
      selector: (row) => (
        <p className="text-base font-roboto-regular text-black py-5">
          ${row.amount}
        </p>
      ),
    },
    {
      name: "Frequency",
      selector: (row) => (
        <p className="text-base font-roboto-regular text-black capitalize">
          {row?.strategy?.split("-")?.[0]}
        </p>
      ),
    },
    {
      name: "Schedule",
      selector: (row) => (
        <p className="text-base font-roboto-regular text-black">
          {row.strategy
            ? (moment(
                new Date().setDate(parseFloat(row?.strategy?.split("-")?.[1]))
              ).format("DD") > moment(new Date()).format("DD")
                ? moment().format("MMMM")
                : moment().add(1, "months").format("MMMM")) +
              " " +
              row?.strategy?.split("-")?.[1]
            : "-"}
        </p>
      ),
    },
    {
      name: "Total Invested",
      selector: (row) => (
        <p
          className={`text-base font-roboto-semibold ${
            parseFloat(row.total) >= 0 ? "text-[#219653]" : "text-red-500"
          }`}
        >
          {SET_FORMAT(parseFloat(row.total ? row.total : 0))}
        </p>
      ),
    },
    {
      name: "Rollover",
      selector: (row) => (
        <p className={`text-base font-roboto-regular text-black`}>
          {SET_FORMAT(row?.holdings)}
        </p>
      ),
    },
    {
      name: "Start/Pause",
      selector: (row) => (
        <div className="p-0">
          {row?.active === true && (
            <Button
              className="shadow-none hover:shadow-none bg-[#EAEAEA] rounded-md normal-case px-5 py-3 text-black font-roboto-base"
              onClick={() => stopAutomationFunction(row?.allocationId)}
            >
              Pause
            </Button>
          )}
          {row?.active === false && (
            <Button
              className="shadow-none hover:shadow-none bg-logoGreen rounded-md normal-case px-5 py-3 text-white font-roboto-base"
              onClick={() => startAutomationFunction(row?.allocationId)}
            >
              Start
            </Button>
          )}
        </div>
      ),
    },
    {
      name: "Actions",
      selector: (row) => (
        <div className="p-0">
          <Menu allowHover placement="right-start">
            <MenuHandler>
              <Button className="p-2 shadow-none hover:shadow-none bg-[#f6f6f6] hover:bg-black hover:bg-opacity-10  transition-all duration-300 ease-in-out">
                <GoKebabHorizontal className="w-5 h-5 text-black" />
              </Button>
            </MenuHandler>
            <MenuList className="w-[15vh] p-2">
              <MenuItem className="bg-white hover:bg-logoGreen hover:bg-opacity-10 rounded-md text-base font-roboto-semibold">
                Details
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setAutomationEditId(row);
                  setAutomationEditDialog(!automationEditDialog);
                }}
                className="bg-white hover:bg-logoGreen hover:bg-opacity-10 rounded-md text-base font-roboto-semibold"
              >
                Edit
              </MenuItem>
              <MenuItem
                onClick={() => {
                  removeAutomation(row?.allocationId);
                }}
                className="bg-white hover:bg-logoGreen hover:bg-opacity-10 rounded-md text-base font-roboto-semibold"
              >
                Remove
              </MenuItem>
              <MenuItem className="bg-white hover:bg-logoGreen hover:bg-opacity-10 rounded-md text-base font-roboto-semibold">
                Reset Rollover
              </MenuItem>
            </MenuList>
          </Menu>
        </div>
      ),
    },
  ];

  const AccountAccordion = (props) => {
    return (
      <div
        className="p-3 bg-greyBg bg-opacity-50 border border-greyBorder rounded-lg mt-2 cursor-pointer"
        onClick={() => handleGetAuthLink(props?.slug)}
      >
        <div className="flex items-center gap-3">
          <img
            src={props?.brokerImg}
            className="w-10 h-10 object-contain rounded"
            alt={props?.brokerName}
          />
          <div className="p-0">
            <h5 className="text-base font-roboto-semibold text-black">
              {props?.brokerName}
            </h5>
          </div>
        </div>
      </div>
    );
  };

  /* USE-EFFECT */
  // useEffect(() => {
  //   setAutomationSelected(accountsList?.accounts?.[0]?.accountId);
  //   getAutomationList();
  // }, []);

  useEffect(() => {
    let tempArray = [];
    if (accountsList?.accounts) {
      allConnectedAccountsList?.accounts?.forEach((el, index) => {
        tempArray.push({
          order: index + 1,
          accountId: el?.accountId,
          accountDetails: el,
          brokerage: accountsList?.accounts?.find(
            (ele) => ele?.accountId === el?.accountId
          )?.brokerage,
          automations: [],
        });
      });
    }
    setAutomationList(tempArray);
  }, [accountsList?.accounts]);

  useEffect(() => {
    if (automationList?.length > 0 && apiCall) {
      getAutomationList();
      setApiCall(false);
    }
  }, [automationList]);
  return (
    <section>
      {/* PAGE TITLE */}
      <Helmet>
        <title>Automate | ScaleTrade</title>
      </Helmet>

      {/* TOP BOX */}
      <div
        className={`${
          !helperText && "hidden"
        } w-full bg-[#008037] bg-opacity-10 px-10 py-5`}
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <div className="p-0">
              <h5 className="text-md font-roboto-semibold text-black">
                🤔 Have you setup automatic cash transfers yet?
              </h5>
              <p className="mt-1 text-base font-roboto-regular text-black">
                This is a critical step to make sure you have enough money for
                your automation that runs every month. Learn how to set up
                automatic money transfers.
              </p>
            </div>
          </div>
          <div className="flex items-center gap-3">
            <Button className="w-fit mx-auto whitespace-nowrap shadow-none hover:shadow-none bg-logoGreen hover:bg-opacity-80 rounded-md normal-case text-base font-roboto-semibold transition-all duration-300 ease-in-out">
              Learn more
            </Button>
            <Button
              onClick={() => {
                setHelperText(!helperText);
              }}
              className="shadow-none hover:shadow-none p-2 rounded-full bg-transparent hover:bg-black hover:bg-opacity-10 transition-all duration-300 ease-in-out"
            >
              <IoClose className="w-4 h-4 text-black" />
            </Button>
          </div>
        </div>
      </div>

      <div className="px-3 xl:px-10 lg:px-3 py-4 xl:py-7 lg:py-7">
        <div className="container mx-auto">
          <div className="w-full">
            {/* HEADING */}
            <div className="block xl:flex lg:flex items-center justify-between">
              <h1 className="text-2xl xl:text-4xl lg:text-4xl font-roboto-semibold text-black leading-tight">
                Your Accounts and Automations
              </h1>
              <Button
                className="mt-3 xl:mt-0 lg:mt-0 w-full xl:w-fit lg:w-fit shadow-none hover:shadow-none bg-logoGreen normal-case text-white hover:text-opacity-80 px-8 py-3 text-base font-roboto-base transition-all duration-300 ease-in-out"
                onClick={() => {
                  accountsList?.accounts?.length === 0 ||
                  accountsList?.accounts?.length === undefined
                    ? toast.error("Please connect broker")
                    : setStrategySetup(!strategySetup);
                }}
              >
                Create new automation
              </Button>
            </div>

            {/* AUTOMATIONS LIST */}
            <div className="mt-5">
              {automationList?.length === 0 ? (
                <div className="w-full bg-[#F6F6F6] border border-inputBox py-4 rounded-md">
                  <p className="text-base font-roboto-semibold text-black text-center">
                    No accounts or automations found
                  </p>
                </div>
              ) : (
                <>
                  {automationList?.map((data) => (
                    <React.Fragment key={data.accountId}>
                      <div
                        className={`w-full  bg-[#F6F6F6] rounded-lg px-5 first:mt-0 mt-5`}
                      >
                        {/* HEADING */}
                        <div className="py-3 ">
                          <div className="flex items-center justify-between">
                            <div className="flex items-center gap-3">
                              <img
                                src={
                                  data.brokerage === "ALPACA"
                                    ? Alpaca
                                    : data.brokerage === "ALPACA_PAPER"
                                    ? Alpaca
                                    : data.brokerage === "TRADIER"
                                    ? Tradier
                                    : data.brokerage === "ROBINHOOD"
                                    ? Robinhood
                                    : data.brokerage === "TRADESTATION"
                                    ? TradeStation
                                    : data.brokerage === "TRADESTATION_SIM"
                                    ? TradeStation
                                    : data.brokerage === "WEBULL"
                                    ? Webull
                                    : null
                                }
                                className="w-12 h-12 object-contain rounded"
                                alt={data.brokerage}
                              />
                              <div className="p-0">
                                <h5 className="text-lg font-redhat-semibold text-black">
                                  {data.brokerage}
                                </h5>
                                <p className="text-sm xl:text-md lg:text-md font-roboto-semibold text-black">
                                  {data?.accountDetails?.totalValue
                                    ? SET_FORMAT(
                                        data?.accountDetails?.totalValue
                                      )
                                    : SET_FORMAT(0.0)}
                                </p>
                              </div>
                            </div>
                            <p className="text-sm px-4 py-3 rounded-lg xl:text-md lg:text-md font-roboto-semibold text-[#2073f3] bg-[#e6efff]">
                              Cash to Invest:{" "}
                              {data?.accountDetails?.cash
                                ? SET_FORMAT(data?.accountDetails?.cash)
                                : SET_FORMAT(0.0)}
                            </p>
                          </div>
                        </div>

                        {/* BODY */}

                        <div
                          className={
                            data?.automations?.length > 0
                              ? "block py-5 border-t border-[#E5E5E5]"
                              : "hidden py-5 border-t border-[#E5E5E5]"
                          }
                        >
                          {data?.automations?.length > 0 && (
                            <DataTable
                              className="trade-table diff rounded-b-md bg-[#f6f6f6]"
                              data={data?.automations}
                              columns={columns}
                              responsive
                              progressComponent={
                                <div className="w-full bg-greyBg py-4 ">
                                  <p className="text-base font-roboto-semibold text-black text-center">
                                    Please wait...
                                  </p>
                                </div>
                              }
                              progressPending={automationLoading}
                              noDataComponent={
                                <div className="w-full bg-greyBg  text-center align-middle justify-center mx-auto"></div>
                              }
                            />
                          )}
                        </div>
                      </div>
                    </React.Fragment>
                  ))}
                  <Button
                    onClick={() => {
                      setBrokerDialog(!brokerDialog);
                    }}
                    className="mt-5 w-fit mx-auto shadow-none hover:shadow-none bg-transparent hover:bg-logoGreen hover:bg-opacity-10 rounded-md px-5 py-3 normal-case flex items-center gap-2 transition-all duration-300 ease-in-out text-base font-redhat-semibold text-logoGreen"
                  >
                    <IoMdAdd className="w-5 h-5 text-logoGreen" />
                    Connect another brokerage
                  </Button>
                  {/* <div className="w-full bg-greyBg border border-inputBox py-4 rounded-md mt-3 cursor-pointer">
                    <p className="text-base font-roboto-semibold text-gray-600 text-start pl-3">
                      Connect another brokerage
                    </p>
                  </div> */}
                </>
              )}
            </div>

            {/* BROKERAGE OPTIONS */}
            {accountsList?.accounts?.length === 0 && (
              <div className="mt-5 xl:mt-10 lg:mt-10">
                <h5 className="text-lg font-roboto-bold text-black">
                  Connect your brokerage
                </h5>
                <div className="mt-3">
                  <div className="grid grid-cols-2 xl:grid-cols-7 lg:grid-cols-1 gap-5">
                    {brokers.map((data) => (
                      <div className="col-span-1" key={data.id}>
                        <BrokerTile
                          brokerName={data.brokerName}
                          brokerImg={data.brokerImg}
                          slug={data.slug}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* CREATE AUTOMATION DIALOG */}
      <Dialog
        open={automationDialog}
        handler={() => setAutomationDialog(!automationDialog)}
        size="xl"
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        className="bg-automationDialog overflow-visible"
      >
        <DialogBody className="p-4 xl:p-8 lg:p-8 max-h-[90vh] overflow-auto overflow-y-auto overflow-x-hidden hide-scrollbar z-top relative">
          <CreateAutomationComponent
            setAutomationDialog={setAutomationDialog}
            setAutomationStatus={setAutomationStatus}
            getAutomationsList={getAutomationList}
            automationAccountSelected={automationAccountSelected}
          />
        </DialogBody>
      </Dialog>

      {/* AUTOMATION SAVED */}
      <Dialog
        open={automationStatus}
        handler={() => setAutomationStatus(!automationStatus)}
        size="xs"
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        className="bg-automationDialog overflow-visible"
      >
        <DialogBody className="px-5 py-10">
          <ReactConfetti className="w-full h-full" />
          <h5 className="text-2xl font-roboto-bold text-white text-center">
            Automation saved!
          </h5>
          <img
            src={require("../../../assets/images/icons/check.png")}
            className="w-20 h-20 mt-5 mx-auto"
            alt="Automation saved"
          />
          <div className="mt-5">
            <p className="w-4/5 text-lg font-roboto-semibold text-white text-center mx-auto">
              Congratulations on setting up your automated investment.
            </p>
          </div>
          <div className="flex items-center mt-3">
            <Button
              className="mt-3 xl:mt-0 lg:mt-0 w-full xl:w-fit lg:w-fit shadow-none hover:shadow-none bg-gradient normal-case text-white hover:text-opacity-80 px-8 py-3 text-base font-roboto-semibold transition-all duration-300 ease-in-out mx-auto"
              onClick={() => {
                !userDetails?.isSubscribed
                  ? window.open(
                      `${PAYMENT_LINK}?client_reference_id=${userId}`,
                      "_blank",
                      "noopener,noreferrer"
                    )
                  : setAutomationStatus(!automationStatus);
              }}
            >
              {!userDetails?.isSubscribed ? "Subscribe" : "Continue"}
            </Button>
          </div>
          <div className="absolute top-1 right-1">
            <Button
              className="shadow-none hover:shadow-none rounded-full bg-transparent hover:bg-white hover:bg-opacity-10 p-2 transition-all duration-300 ease-in-out"
              onClick={() => setAutomationStatus(!automationStatus)}
            >
              <IoClose className="w-5 h-5 text-white" />
            </Button>
          </div>
        </DialogBody>
      </Dialog>

      {/* BROKER */}
      <Dialog
        open={brokerDialog}
        handler={() => setBrokerDialog(!brokerDialog)}
        size="xs"
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        className="min-w-[90%] xl:min-w-max lg:min-w-max relative outline-none focus:outline-none"
      >
        <DialogBody className="p-0 relative outline-none focus:outline-none">
          <div className="py-5 border-b border-inputBox">
            <img
              src={require("../../../assets/images/scaletrade-logo-black.png")}
              className="h-10 w-auto mx-auto"
              alt="ScaleTrade"
            />
          </div>
          <div className="py-5 px-8">
            <h3 className="text-xl font-roboto-extrabold text-black">
              Let's link another account
            </h3>
            <p className="mt-1 text-base font-roboto-regular text-[#494949]">
              Select your brokerage below.
            </p>
            <div className="mt-3">
              {brokers.map((data) => (
                <React.Fragment key={data.id}>
                  <AccountAccordion
                    brokerName={data.brokerName}
                    brokerImg={data.brokerImg}
                    slug={data.slug}
                  />
                </React.Fragment>
              ))}
            </div>
            <div className="mt-5">
              <p
                onClick={() => {
                  setBrokerageNotFoundDialog(!brokerageNotFoundDialog);
                  setBrokerDialog(!brokerDialog);
                }}
                className="text-base font-roboto-semibold text-greyText text-center"
              >
                <span className="underline cursor-pointer">
                  I don't see my brokerage here
                </span>
              </p>
              <p className="text-sm font-roboto-semibold text-gray-500 text-center mt-5">
                By linking your brokerage and starting automations, you agree to
                the terms of our Disclosure Agreement
              </p>
            </div>
          </div>
        </DialogBody>
      </Dialog>

      {/* STRATEGY SETUP MODAL */}
      <Dialog
        open={strategySetup}
        handler={() => setStrategySetup(!strategySetup)}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        size="xs"
      >
        <DialogBody className="p-5">
          {step === 1 && (
            <NewCreateAutomationComponent
              setAutomationDialog={setStrategySetup}
              strategySetup={strategySetup}
              setStep={setStep}
            />
          )}

          {step === 2 && (
            <div className="p-0">
              <h5 className="text-lg font-roboto-bold text-black text-center">
                Your Automation is Ready!
              </h5>
              <div className="mt-3">
                <img
                  src={require("../../../assets/images/icons/automation-success.png")}
                  className="w-4/5 mx-auto h-auto"
                  alt="Your automation is ready"
                />
              </div>
              <div className="mt-5">
                <h5 className="text-md font-roboto-semibold text-black text-center">
                  Start your ScaleTrade plan to begin using your new automation.{" "}
                </h5>
              </div>
              <div className="flex items-center justify-center">
                <Button
                  className="mt-5 w-fit mx-auto px-10 shadow-none hover:shadow-none bg-logoGreen hover:bg-opacity-80 rounded-md normal-case text-base font-roboto-bold transition-all duration-300 ease-in-out"
                  onClick={() => {
                    setStep(1);
                    !userDetails?.isSubscribed
                      ? window.open(
                          `${PAYMENT_LINK}?client_reference_id=${userId}`,
                          "_blank",
                          "noopener,noreferrer"
                        )
                      : setStrategySetup(!strategySetup);
                  }}
                >
                  {!userDetails?.isSubscribed
                    ? "Start ScaleTrade Plan"
                    : "Continue"}
                </Button>
              </div>
            </div>
          )}
        </DialogBody>
      </Dialog>

      {/* EDIT AUTOMATION DIALOG */}
      <Dialog
        open={automationEditDialog}
        handler={() => setAutomationEditDialog(!automationEditDialog)}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        size="xs"
      >
        <DialogBody className="p-5">
          <ChangeAutomation
            automationId={automationEditId}
            setAutomationDialog={setAutomationEditDialog}
            strategySetup={automationEditDialog}
            getAutomationsList={getAutomationList}
            automationAccountSelected={automationAccountSelected}
          />
        </DialogBody>
      </Dialog>

      {/* NEW BROKERAGES */}
      <Dialog
        open={brokerageNotFoundDialog}
        handler={() => {
          setBrokerageNotFoundDialog(!brokerageNotFoundDialog);
          setBrokerageNotFoundStep(1);
        }}
        size="xs"
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        className="min-w-[90%] xl:min-w-max lg:min-w-max relative outline-none focus:outline-none"
      >
        <DialogBody className="p-0 relative outline-none focus:outline-none">
          <div className="py-5 border-b border-inputBox border-opacity-30">
            <img
              src={require("../../../assets/images/scaletrade-logo-black.png")}
              className="h-10 w-auto mx-auto"
              alt="ScaleTrade"
            />
          </div>
          <div className="py-5 px-8 ">
            {brokerageNotFoundStep === 1 && (
              <>
                <h5 className="text-lg font-roboto-bold text-black">
                  We add new brokers all the time
                </h5>
                <p className="text-base font-roboto-semibold text-black mt-1">
                  Please tell us which broker you're looking for, and we will
                  notify you when it is added to ScaleTrade.
                </p>
                <form className="mt-3" onSubmit={formik.handleSubmit}>
                  <input
                    type="text"
                    name="brokerageName"
                    id="etfName"
                    value={formik.values.brokerageName}
                    onChange={formik.handleChange}
                    placeholder="Enter the broker name"
                    className={`block w-full bg-white border ${
                      formik.errors.brokerageName &&
                      formik.touched.brokerageName
                        ? "border-red-500"
                        : "border-inputBox"
                    } outline-none focus:outline-none text-base font-roboto-base text-black px-5 py-3 rounded-md`}
                  />
                  <Button
                    className="mt-5 w-full shadow-none hover:shadow-none text-base font-roboto-base text-white bg-[#008037] normal-case"
                    type="submit"
                    disabled={brokerageNotFoundLoading}
                  >
                    {brokerageNotFoundLoading
                      ? "Please wait.."
                      : "Submit and notify me when it's added"}
                  </Button>
                </form>
              </>
            )}

            {brokerageNotFoundStep === 2 && (
              <>
                <h5 className="text-lg font-roboto-bold text-black">
                  Your request has been submitted!
                </h5>
                <p className="text-base font-roboto-semibold text-black mt-1">
                  We will notify you via email when this broker is added to
                  ScaleTrade.
                </p>
                <Button
                  className="mt-5 w-full shadow-none hover:shadow-none text-base font-roboto-semibold text-white bg-[#008037] normal-case"
                  onClick={() => {
                    setBrokerageNotFoundStep(1);
                    setBrokerageNotFoundDialog(!brokerageNotFoundDialog);
                  }}
                >
                  Done
                </Button>
              </>
            )}
          </div>

          <div className="absolute top-2 right-2">
            <Button
              className="shadow-none hover:shadow-none bg-transparent rounded-full p-2 hover:bg-white hover:bg-opacity-10 transition-all duration-300 ease-in-out"
              onClick={() => {
                setBrokerageNotFoundStep(1);
                setBrokerageNotFoundDialog(!brokerageNotFoundDialog);
              }}
            >
              <IoClose className="w-4 h-4 text-inputBox text-opacity-50s" />
            </Button>
          </div>
        </DialogBody>
      </Dialog>
    </section>
  );
};

export default Automate;
