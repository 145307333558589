/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
module.exports.calculateCompoundInterest = (values) => {
  let P = parseFloat(values?.principalAmount);
  let PMT = parseFloat(values?.monthlyContribution);
  let r = values?.interestRate / 100;
  let t = values?.timePeriodRange?.value;
  let n = values?.timePeriod;

  let totalAmountInvested = P;

  // Convert annual interest rate to decimal

  // Calculate compound interest
  var amount = P * Math.pow(1 + r / n, n * t);

  // Calculate compound interest with monthly contributions
  var totalAmount = P;
  let amountsGraph = [];
  let withoutInvestmentGraph = [P];
  for (var i = 0; i < n * t; i++) {
    totalAmount *= 1 + r / t;
    // Add monthly contribution
    totalAmount += PMT * 12;
    totalAmountInvested += PMT * 12;
    // Apply interest for each period
    withoutInvestmentGraph.push(totalAmountInvested);
    amountsGraph.push(totalAmount.toFixed(2));
  }

  let labels = Array.from({ length: n * t + 1 }, (_, i) => `${Math.floor(i)}`);

  amountsGraph.unshift(P);

  return {
    totalAmount,
    graphData: amountsGraph,
    labels,
    totalAmountInvested,
    withoutInvestmentGraph,
  };
};
