import { useState } from "react";

/* PACKAGES */
import { motion } from "framer-motion";

/* ICONS */
import { FiMinus } from "react-icons/fi";
import { IoMdAdd } from "react-icons/io";
import { BsFillPatchCheckFill } from "react-icons/bs";

/* MATERIAL TAILWIND COMPONENTS */
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Button,
  Card,
  CardBody,
} from "@material-tailwind/react";

/* ANIMATION */
import { animation, containerAnimation } from "config/animation";
import AccountCreation from "components/others/accountCreation";

const CUSTOM_ANIMATION = {
  mount: { scale: 1 },
  unmount: { scale: 0.9 },
};

const Pricing = () => {
  /* ACCORDIONS */
  const [accordion01, setAccordion01] = useState(false);
  const [accordion02, setAccordion02] = useState(false);
  const [accordion03, setAccordion03] = useState(false);
  const [accordion04, setAccordion04] = useState(false);

  const [currentTab, setCurrentTab] = useState("login");
  const [currentState, setCurrentState] = useState(false);

  return (
    <motion.section
      variants={containerAnimation}
      initial="hidden"
      animate="visible"
      className="bg-white xl:bg-[#F1F1F1] lg:bg-[#F1F1F1] overflow-y-clip"
    >
      {/* PRICING PLAN */}
      <motion.div
        variants={animation}
        className="py-5 xl:py-20 lg:py-20 z-20 relative"
      >
        <div className="container mx-auto">
          <div className="p-0">
            <h1 className="text-3xl xl:text-4xl lg:text-4xl font-redhat-extrabold text-black text-center leading-tight xl:leading-normal lg:leading-normal">
              Simple & transparent pricing
            </h1>
            <h2 className="text-xl font-redhat-regular text-greyText text-center">
              No hidden fees, no percentages, no transfer of assets.
            </h2>
          </div>

          <div className="mt-5 xl:mt-10 lg:mt-10 w-full xl:w-2/5 lg:w-2/5 mx-auto">
            <Card className="shadow-lg bg-gradient-to-br from-white to-[#F5F5F5] border border-greyBorder rounded-lg border-opacity-20">
              <CardBody className="py-0 px-10">
                <div className="py-5 border-b border-greyBorder">
                  <h5 className="text-lg font-redhat-bold text-black text-center">
                    ScaleTrade Investing Toolset
                  </h5>
                </div>
                <div className="py-10">
                  <div className="flex items-end justify-center gap-2">
                    <h5 className="text-4xl font-redhat-extrabold text-black leading-none">
                      $5.99
                    </h5>
                    <p className="text-md font-redhat-regular text-black">
                      / month
                    </p>
                  </div>
                  <div className="w-fit mx-auto mt-8">
                    <div className="flex items-center gap-2 w-fit">
                      <BsFillPatchCheckFill className="w-5 h-5 text-logoGreen" />
                      <p className="text-base font-redhat-regular text-black">
                        Unlimited automations
                      </p>
                    </div>
                    <div className="flex items-center gap-2 mt-2 w-fit">
                      <BsFillPatchCheckFill className="w-5 h-5 text-logoGreen" />
                      <p className="text-base font-redhat-regular text-black">
                        Portfolio Dashboard & Insights
                      </p>
                    </div>
                    <div className="flex items-center gap-2 mt-2 w-fit">
                      <BsFillPatchCheckFill className="w-5 h-5 text-logoGreen" />
                      <p className="text-base font-redhat-regular text-black">
                        Multiple account trading
                      </p>
                    </div>
                    <div className="flex items-center gap-2 mt-2 w-fit">
                      <BsFillPatchCheckFill className="w-5 h-5 text-logoGreen" />
                      <p className="text-base font-redhat-regular text-black">
                        Select up to three stocks to trade
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center justify-center">
                    <Button  onClick={() => {
                setCurrentTab("register");
                setCurrentState(true);
              }} className="mt-5 mx-auto px-10 py-3 shadow-none hover:shadow-none bg-gradient-to-r from-[#477AFF] to-[#042B8F] normal-case font-redhat-semibold text-base">
                      Get started for free
                    </Button>
                  </div>
                  <p className="mt-5 text-sm font-redhat-regular text-greyText text-center">
                    Cancel any time, refunds upon request.
                  </p>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </motion.div>

      {/* TRUSTPILOT BADGE */}
      <motion.div
        variants={animation}
        className="py-5 xl:py-12 lg:py-12 relative"
      >
        {/* BACKGROUND */}
        <div className="absolute -top-[60vh] left-0 z-10 hidden xl:block lg:block">
          <img
            src={require("../../assets/images/website/pricing-bg.png")}
            className="w-full h-auto"
            alt="Simple and transparent pricing"
          />
        </div>

        <div className="container mx-auto relative z-20">
          <div className="w-full xl:w-1/2 lg:w-1/2 mx-auto">
            <div className="block xl:flex lg:flex items-center justify-center gap-10">
              <img
                src={require("../../assets/images/website/trustpilot.png")}
                className="w-auto h-16 object-cover"
                alt="Trustpilot Badge"
              />
              <h5 className="mt-5 xl:mt-0 lg:mt-0 text-md font-redhat-semibold text-black leading-relaxed">
                “Joining ScaleTrade has allowed me to put a lot of unused cash
                to work. I have been able to add hundreds of dollars in monthly
                income thanks to my subscription.” - Benji W.
              </h5>
            </div>
          </div>
        </div>
      </motion.div>

      {/* FREQUENTLY ASKED QUESTIONS */}
      <motion.div
        variants={animation}
        className="py-6 xl:py-12 lg:py-12 bg-white z-20 relative"
      >
        <div className="container mx-auto">
          <h2 className="text-2xl xl:text-3xl lg:text-3xl font-redhat-extrabold text-black text-center">
            Frequently Asked Questions
          </h2>
          <div className="mt-5 xl:mt-10 lg:mt-10">
            <Accordion
              open={accordion01}
              animate={CUSTOM_ANIMATION}
              className="border-t border-b border-black"
              icon={
                accordion01 ? (
                  <FiMinus className="w-5 h-5 text-black" />
                ) : (
                  <IoMdAdd className="w-5 h-5 text-black" />
                )
              }
            >
              <AccordionHeader
                className="border-b-0 border-transparent bg-white py-3 text-md font-redhat-bold text-black"
                onClick={() => setAccordion01(!accordion01)}
              >
                What happens after the free trial ends?
              </AccordionHeader>
              <AccordionBody className="pb-3 pt-0 bg-white">
                <p className="text-base font-redhat-regular text-black leading-relaxed">
                  Once your free trial ends, you will automatically be charged
                  for your subscription and your automations will be
                  uninterrupted. However, iIf you choose to cancel your
                  subscription, your automations will be stopped and your
                  brokerage account will be disconnected.
                </p>
              </AccordionBody>
            </Accordion>

            <div className="mt-0">
              <Accordion
                open={accordion02}
                animate={CUSTOM_ANIMATION}
                className="border-t-0 border-b border-black"
                icon={
                  accordion02 ? (
                    <FiMinus className="w-5 h-5 text-black" />
                  ) : (
                    <IoMdAdd className="w-5 h-5 text-black" />
                  )
                }
              >
                <AccordionHeader
                  className="border-b-0 border-transparent bg-white py-3 text-md font-redhat-bold text-black"
                  onClick={() => setAccordion02(!accordion02)}
                >
                  What is the cancellation and refund policy?
                </AccordionHeader>
                <AccordionBody className="pb-3 pt-0 bg-white">
                  <p className="text-base font-redhat-regular text-black leading-relaxed">
                    Traders can cancel and request a refund at any time.
                  </p>
                </AccordionBody>
              </Accordion>
            </div>

            <div className="mt-0">
              <Accordion
                open={accordion03}
                animate={CUSTOM_ANIMATION}
                className="border-t-0 border-b border-black"
                icon={
                  accordion03 ? (
                    <FiMinus className="w-5 h-5 text-black" />
                  ) : (
                    <IoMdAdd className="w-5 h-5 text-black" />
                  )
                }
              >
                <AccordionHeader
                  className="border-b-0 border-transparent bg-white py-3 text-md font-redhat-bold text-black"
                  onClick={() => setAccordion03(!accordion03)}
                >
                  Which brokerages does ScaleTrade support?
                </AccordionHeader>
                <AccordionBody className="pb-3 pt-0 bg-white">
                  <p className="text-base font-redhat-regular text-black leading-relaxed">
                    We currently support TD Ameritrade, and have plans to
                    support Robinhood, Interactive Brokers, and Tradier within
                    the next year.
                  </p>
                </AccordionBody>
              </Accordion>
            </div>

            <div className="mt-0">
              <Accordion
                open={accordion04}
                animate={CUSTOM_ANIMATION}
                className="border-t-0 border-b border-black"
                icon={
                  accordion04 ? (
                    <FiMinus className="w-5 h-5 text-black" />
                  ) : (
                    <IoMdAdd className="w-5 h-5 text-black" />
                  )
                }
              >
                <AccordionHeader
                  className="border-b-0 border-transparent bg-white py-3 text-md font-redhat-bold text-black"
                  onClick={() => setAccordion04(!accordion04)}
                >
                  Can I connect multiple brokerage acounts?
                </AccordionHeader>
                <AccordionBody className="pb-3 pt-0 bg-white">
                  <p className="text-base font-redhat-regular text-black leading-relaxed">
                    At this time, you are only able to connect one brokerage
                    account per ScaleTrade account. However, we are working to
                    develop this functionality in the future.
                  </p>
                </AccordionBody>
              </Accordion>
            </div>
          </div>
        </div>
      </motion.div>

      <AccountCreation
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        currentState={currentState}
        setCurrentState={setCurrentState}
      />
    </motion.section>
  );
};

export default Pricing;
