import { useCallback, useEffect, useState } from "react";
import { getDividendInformationApi } from "service/api";

const useDividendHelper = () => {
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [barGraphData, setBarGraphData] = useState(null);
  const [dividendInformation, setDividendInformation] = useState(null);
  const [estimatedDividend, setEstimatedDividend] = useState(0);
  const estimatedDividends = [];
  const calculateDividends = (dividendAmount, frequency, payoutDate, units) => {
    const initialPayoutDate = new Date(payoutDate);
    const totalDividendPerPayout = dividendAmount * units;
    const monthsInterval = 12 / frequency;
    const payouts = [];

    for (let i = 0; i < frequency; i++) {
      const payoutDate = new Date(initialPayoutDate);
      payoutDate.setMonth(payoutDate.getMonth() + i * monthsInterval);

      payouts.push({
        month: payoutDate.toLocaleString("default", { month: "long" }),
        year: payoutDate.getFullYear(),
        amount: totalDividendPerPayout.toFixed(2),
      });
    }

    estimatedDividends.push(payouts);
    // return payouts;
  };

  const calculateDividendFunction = async () => {
    if (dividendInformation) {
      const tickerMap = new Map();

      dividendInformation?.tickers.forEach((item) => {
        const { ticker, units } = item;
        tickerMap.set(ticker, (tickerMap.get(ticker) || 0) + units);
      });

      const results = Array.from(tickerMap.entries()).map(
        ([ticker, units]) => ({
          ticker,
          units,
        })
      );

      // Process data in smaller chunks
      const chunkSize = 10; // Adjust this size based on your performance testing

      for (let i = 0; i < results.length; i += chunkSize) {
        const chunk = results.slice(i, i + chunkSize);

        await Promise.all(
          chunk.map(async (element) => {
            const selectedTicker = dividendInformation?.securities?.find(
              (e) => e?.underlying === element?.ticker
            );

            if (selectedTicker) {
              calculateDividends(
                selectedTicker?.cash_amount,
                selectedTicker?.frequency,
                selectedTicker?.payout_date,
                element?.units
              );
            }
            return [];
          })
        );
      }

      const monthMap = {};
      const monthOrder = [
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
        "January",
        "February",
        "March",
        "April",
        "May",
      ];

      estimatedDividends.forEach((item) => {
        if (item?.length > 0) {
          item?.forEach((element) => {
            const { month, amount } = element;
            if (!monthMap[month]) {
              monthMap[month] = 0;
            }
            monthMap[month] += parseFloat(amount);
          });
        }
      });

      const sortedLabels = Object.keys(monthMap).sort(
        (a, b) => monthOrder.indexOf(a) - monthOrder.indexOf(b)
      );
      const sortedValues = sortedLabels.map((label) => monthMap[label]);
      const total = sortedValues.reduce((acc, value) => acc + value, 0);

      setEstimatedDividend(total);
      setBarGraphData({
        labels: sortedLabels,
        datasets: [
          {
            label: "Estimated Dividend",
            data: sortedValues,
          },
        ],
      });
    }
  };

  const getDividendCallback = useCallback(
    (allConnectedAccountsList, selectedAccountId) => {
      setDetailsLoading(true);
      let tickerArray = [];
      let tickersList = [];
      if (allConnectedAccountsList?.accounts?.length > 0) {
        if (selectedAccountId === "all") {
          allConnectedAccountsList?.accounts?.forEach((element) => {
            element?.active?.forEach((el) => {
              tickerArray.push(el?.order?.underlying);
              tickersList.push({
                ticker: el?.order?.underlying,
                units: el?.order?.units,
              });
            });
          });
        } else {
          allConnectedAccountsList?.accounts
            ?.filter((el) => el?.accountId === selectedAccountId)
            .forEach((element) => {
              element?.active?.forEach((el) => {
                tickerArray.push(el?.order?.underlying);
                tickersList.push({
                  ticker: el?.order?.underlying,
                  units: el?.order?.units,
                });
              });
            });
        }
      }

      if (tickerArray?.length > 0) {
        getDividendInformationApi({
          securities: [...new Set(tickerArray)],
        })
          .then((res) => {
            if (res?.data?.securities) {
              setDividendInformation({
                securities: res?.data?.securities,
                tickers: tickersList,
              });
            }
            setDetailsLoading(false);
          })
          .catch((e) => {
            console.log("Error=>", e);
            setDetailsLoading(false);
          });
      } else {
        setDividendInformation({
          securities: [],
          tickers: [],
        });
        setDetailsLoading(false);
      }
    },
    []
  );

  useEffect(() => {
    if (dividendInformation) {
      calculateDividendFunction();
    }
  }, [dividendInformation]);

  return {
    estimatedDividend,
    barGraphData,
    detailsLoading,
    getDividendCallback,
  };
};

export default useDividendHelper;
