/* eslint-disable no-unreachable */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

/* IMPORT REACT  */
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import Select from "react-select";

/* IMPORT react-toastify FOR TOAST NOTIFICATION  */
import { toast } from "react-toastify";

/* MATERIAL TAILWIND COMPONENTS */
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Button,
} from "@material-tailwind/react";

/* ICONS */
import { GoArrowLeft } from "react-icons/go";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

/* ASSETS */
import Alpaca from "assets/images/logos/alpaca.png";
import Robinhood from "assets/images/logos/robinhood.png";

import Tradier from "assets/images/logos/tradier.png";
import TradeStation from "assets/images/logos/trade-station.png";
import Webull from "assets/images/logos/webull.png";

/* IMPORT FORMIK FOR FORM-SUBMIT */
import { useFormik } from "formik";
import * as yup from "yup";

/* SAMPLE DATA */
import accountsConnected from "data/accounts";
import {
  getAccountAllDetailsApi,
  getAccountListApi,
  postSubmitEquityOrder,
  submitEquityOrderApi,
} from "service/api";
import { useSelector } from "react-redux";
import { SET_FORMAT } from "config";

/* ACCORDIONN ANIMATION */
const CUSTOM_ANIMATION = {
  mount: { scale: 1 },
  unmount: { scale: 0.9 },
};

/* VALIDATION SCHEMA FOR LOGIN FORM */
const formValidationSchema = yup.object().shape({
  underlying: yup.string().email().required("Symbol is required."),
  units: yup.number().required("Quantity is required."),
  orderType: yup.object().required("Order Type is required."),
  limitValue: yup.string(),
  action: yup.string().required("Action is required."),
  timeInForce: yup.object(),
});

/* ORDER TYPE */
const orderTypeOptions = [
  { value: "LIMIT", label: "Limit order" },
  { value: "STOPLOSS", label: "Stop order" },
  { value: "MARKET", label: "Market order" },
];

/* TIMING OPTIONS */
const timingOptions = [
  { value: "DAY", label: "Day Only" },
  { value: "GTC", label: "Good Till Cancelled" },
];

const CreateOrderComponent = (props) => {
  /* GLOBAL STATE */
  const location = useLocation();
  const userId = useSelector((state) => state?.user?.userId);
  const userDetails = useSelector((state) => state?.user?.user);
  const loggedInStatus = useSelector((state) => state?.user?.loggedIn);
  const accountsList = useSelector((state) => state?.user?.allAccounts);
  const allConnectedAccountsList = useSelector(
    (state) => state?.user?.allConnectedAccounts
  );

  /* STATE VARIABLES */
  const [loading, setLoading] = useState(false);
  const [orderType, setOrderType] = useState("BUY");

  const [accountsCustomList, setAccountsCustomList] = useState([]);

  const [limitPrice, setLimitPrice] = useState(null);

  const [advancedOptions, setAdvancedOptions] = useState(false);

  /* ACCORDION STATES */
  const [accountAccordion, setAccountAccordion] = useState(0);

  const [symbolDetails, setSymbolDetails] = useState(null);

  /* ACCORDION HANDLING */
  const handleOpen = (value) => {
    setAccountAccordion(accountAccordion === value ? 0 : value);
  };

  /* HANDLE FORM USING FORMIK */
  const PlaceOrderFormikFunction = useFormik({
    /* SET INITIAL VALUES FOR LOGIN FORM */
    initialValues: {
      underlying: "",
      units: "",
      orderType: { value: "MARKET", label: "Buy order" },
      limitValue: "",
      action: "",
      timeInForce: { value: "GTC", label: "Good Till Cancelled" },
    },
    /* VALIDATION SCHEMA  */
    validationSchema: formValidationSchema,
    /* HANDLE FORM SUBMIT */
    onSubmit: (values) => {
      /* SET LOADING AS TRUE */
      const obj = {};
      setLoading(true);
      handleSubmit();
    },
  });

  const handleQuantityChange = (details, value) => {
    let currentState = accountsCustomList?.find(
      (item) =>
        item?.broker ===
        accountsList?.accounts?.find((e) => e?.accountId === details?.accountId)
          ?.brokerage
    );

    let filteredData = currentState?.accounts?.map((item) =>
      item.accountId === details?.accountId
        ? { ...item, quantity: parseFloat(value) }
        : item
    );

    setAccountsCustomList((prevItems) =>
      prevItems.map((item) =>
        item.broker ===
        accountsList?.accounts?.find((e) => e?.accountId === details?.accountId)
          ?.brokerage
          ? { ...item, accounts: filteredData }
          : item
      )
    );
  };

  useEffect(() => {
    if (props) {
      setSymbolDetails(props?.symbolDetails);
    }
  }, [props]);

  /* ACCOUNT ACCORDION */
  const AccountAccordion = (props) => {
    return (
      <div className="mt-2 first:mt-0">
        <Accordion
          open={accountAccordion === props.index}
          animate={CUSTOM_ANIMATION}
          className="border border-inputBox rounded-md"
        >
          <AccordionHeader
            className="w-full bg-white p-3 rounded-md flex items-center justify-between trade-accordion border-b-0"
            onClick={() => handleOpen(props.index)}
          >
            <div className="flex items-center gap-3">
              <img
                src={props.image}
                className="w-10 h-10 rounded"
                alt={props.broker}
              />
              <div className="p-0">
                <h5 className="text-base font-redhat-semibold text-black">
                  {props?.broker} # ---
                  {props?.brokerID?.split("").slice(-4)}
                </h5>
                <p className="text-sm font-redhat-semibold text-greyText mt-1">
                  ${props?.cashAvailable} available to trade {props.stock}
                </p>
              </div>
            </div>
            <div className="">
              <h5 className="text-base font-redhat-bold text-black">
                ${props.price}
              </h5>
              {accountAccordion === props.index ? (
                <IoIosArrowUp className="w-5 h-5 text-greyText mt-1 ml-auto" />
              ) : (
                <IoIosArrowDown className="w-5 h-5 text-greyText mt-1 ml-auto" />
              )}
            </div>
          </AccordionHeader>
          <AccordionBody className="bg-white p-3 border-t border-inputBox rounded-b-md">
            <div className="flex items-center gap-2">
              <p className="text-sm font-redhat-semibold text-black">
                Quantity of shares
              </p>
              <input
                name="sharesQty"
                id="sharesQty"
                type="number"
                placeholder="Quantity"
                value={props?.details?.quantity}
                onChange={(e) => {
                  handleQuantityChange(props?.details, e?.target?.value);
                }}
                className="w-1/3 block bg-white border border-inputBox px-4 py-2 rounded-md outline-none focus:outline-none text-sm font-redhat-semibold text-black placeholder:font-redhat-regular"
              />
            </div>
            <div className="mt-2">
              <p className="text-sm font-redhat-semibold text-black">
                Estimated order value:{" "}
                <span className="font-redhat-extrabold">
                  $
                  {props?.details?.quantity
                    ? SET_FORMAT(
                        parseFloat(
                          props?.details?.quantity * symbolDetails?.price
                        ).toFixed(2)
                      )
                    : 0}
                </span>
              </p>
            </div>
          </AccordionBody>
        </Accordion>
      </div>
    );
  };

  const handleSubmit = () => {
    let tempArray = [];
    accountsCustomList?.forEach((element, index) => {
      element?.accounts?.forEach((el) => {
        if (el?.quantity) {
          let query = {
            accountId: el?.accountId,
            underlying: symbolDetails?.underlying,
            units: el?.quantity,
            fill: `${PlaceOrderFormikFunction.values.orderType.value}:${
              PlaceOrderFormikFunction.values.orderType.value === "MARKET"
                ? "[]"
                : PlaceOrderFormikFunction.values.limitValue
                ? "[" + PlaceOrderFormikFunction.values.limitValue + "]"
                : "[]"
            }`,
            action: orderType,
          };
          if (PlaceOrderFormikFunction.values.timeInForce.value) {
            query.timeInForce =
              PlaceOrderFormikFunction.values.timeInForce.value;
          }
          tempArray.push(query);
        }
      });
    });

    setLoading(true);
    if (tempArray.length === 0) {
      toast.error("Please select any one account");
      setLoading(false);
    } else {
      submitEquityOrderApi(userId, { orders: tempArray })
        .then((res) => {
          /* CHECK RESPONSE STATUS */
          if (res?.status_code === 200) {
            toast.success(res?.message);
            props.setView("placed");
          } else {
            /* ERROR NOTIFICATION */
            toast.error(res?.message);
          }
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          toast.error(e?.response?.data?.message);
        })
        .finally((res) => {
          setLoading(false);
        });
    }
  };

  const getAccountListFunction = () => {
    getAccountAllDetailsApi(userId)
      .then((res) => {
        let tempArray = [];
        res?.data?.accounts?.forEach((element) => {
          let brokerageName = accountsList?.accounts?.find(
            (el) => el?.accountId === element?.accountId
          )?.brokerage;

          if (tempArray?.find((el) => el?.broker === brokerageName)) {
            let temp = tempArray?.find(
              (el) => el?.broker === brokerageName
            )?.accounts;
            element.quantity = 0;
            element.orderPrice = 0;
            temp.push(element);
          } else {
            element.quantity = 0;
            element.orderPrice = 0;
            tempArray.push({
              broker: brokerageName,
              accounts: [element],
            });
          }
        });
        setAccountsCustomList(tempArray);
      })
      .catch((e) => {
        toast.info("No Allocation Found");
      });
  };

  useEffect(() => {
    getAccountListFunction();
  }, []);

  return (
    <div className="w-full  bg-greyBg border border-greyBorder rounded-md p-5 mx-auto">
      <form onSubmit={PlaceOrderFormikFunction.handleSubmit}>
        <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-y-5 xl:gap-10 lg:gap-10">
          <div className="col-span-1">
            <div className="w-full xl:w-4/5 lg:w-4/5">
              {/* STOCK INFORMATION */}
              <div className="mt-0">
                <p className="text-lg font-redhat-extrabold text-black">
                  1. Create your trade
                </p>
                <div className="mt-3">
                  <div className="w-full bg-white border border-inputBox rounded-md py-2 px-3">
                    <div className="flex items-center justify-between">
                      <h5 className="text-base font-redhat-semibold text-black">
                        {symbolDetails?.underlying}
                      </h5>
                      <div className="p-0 text-right">
                        <p className="text-sm font-redhat-regular text-black">
                          {SET_FORMAT(symbolDetails?.price)}
                        </p>

                        <p className="text-sm font-redhat-regular text-black">
                          {SET_FORMAT(
                            (
                              (symbolDetails?.price *
                                symbolDetails?.percent_change) /
                              100
                            ).toFixed(2)
                          )}{" "}
                          ({symbolDetails?.percent_change}%)
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-2">
                  <div className="flex items-center justify-between">
                    <div className="p-0">
                      <p className="text-sm font-redhat-regular text-black">
                        Bid: ${symbolDetails?.bid}
                      </p>
                    </div>
                    <div className="p-0">
                      <p className="text-sm font-redhat-regular text-black">
                        Ask: ${symbolDetails?.ask}
                      </p>
                    </div>
                    <div className="p-0">
                      <p className="text-sm font-redhat-regular text-black">
                        Last: ${symbolDetails?.last}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* BUY/SELL/SHORT ORDER */}
              <div className="mt-5">
                <div className="grid grid-cols-2 gap-5">
                  <div className="col-span-1">
                    <Button
                      className={`w-full shadow-none hover:shadow-none ${
                        orderType !== "BUY"
                          ? "bg-white hover:bg-lightGreen hover:bg-opacity-10 border border-inputBox"
                          : "bg-lightGreen bg-opacity-10 border border-lightGreen"
                      }  rounded-md py-2 text-black normal-case font-redhat-semibold text-base transition-all duration-300 ease-in-out`}
                      onClick={() => setOrderType("BUY")}
                    >
                      Buy
                    </Button>
                  </div>
                  <div className="col-span-1">
                    <Button
                      className={`w-full shadow-none hover:shadow-none ${
                        orderType !== "SELL"
                          ? "bg-white hover:bg-lightGreen hover:bg-opacity-10 border border-inputBox"
                          : "bg-lightGreen bg-opacity-10 border border-lightGreen"
                      }  rounded-md py-2 text-black normal-case font-redhat-semibold text-base transition-all duration-300 ease-in-out`}
                      onClick={() => setOrderType("SELL")}
                    >
                      Sell
                    </Button>
                  </div>
                </div>
              </div>

              {advancedOptions ? (
                <>
                  {/* ORDER TYPE */}
                  <div className="mt-5">
                    <div className="form-group">
                      <label className="text-sm font-redhat-semibold text-black">
                        Order type
                      </label>
                      <div className="mt-1">
                        <Select
                          options={orderTypeOptions}
                          classNamePrefix="order"
                          placeholder="Select order type"
                          className="react-select"
                          onChange={(e) =>
                            PlaceOrderFormikFunction.setFieldValue(
                              "orderType",
                              e
                            )
                          }
                          value={PlaceOrderFormikFunction.values.orderType}
                        />
                      </div>
                    </div>
                  </div>

                  {/* LIMIT PRICE */}
                  {PlaceOrderFormikFunction.values.orderType.value !==
                    "MARKET" && (
                    <div className="mt-5">
                      <div className="form-group">
                        <label className="text-sm font-redhat-semibold text-black">
                          {PlaceOrderFormikFunction.values.orderType.value ===
                          "LIMIT"
                            ? "Limit"
                            : "Stop"}{" "}
                          Price
                        </label>
                        <div className="mt-1">
                          <input
                            name="limitValue"
                            id="limitValue"
                            type="number"
                            placeholder={"Price"}
                            className="block w-full bg-white border border-inputBox px-4 py-2 rounded-md outline-none focus:outline-none text-sm font-redhat-semibold text-black placeholder:font-redhat-regular"
                            onChange={(e) =>
                              PlaceOrderFormikFunction.setFieldValue(
                                "limitValue",
                                e?.target?.value
                              )
                            }
                            min={0}
                            value={PlaceOrderFormikFunction.values.limitValue}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {/* TIMING */}
                  <div className="mt-5">
                    <div className="form-group">
                      <label className="text-sm font-redhat-semibold text-black">
                        Timing
                      </label>
                      <div className="mt-1">
                        <Select
                          options={timingOptions}
                          classNamePrefix="order"
                          placeholder="Select timing"
                          className="react-select"
                          onChange={(e) =>
                            PlaceOrderFormikFunction.setFieldValue(
                              "timeInForce",
                              e
                            )
                          }
                          value={PlaceOrderFormikFunction.values.timeInForce}
                        />
                      </div>
                    </div>
                  </div>

                  {/* ADVANCED OPTIONS ANND GO BACK */}
                  <div className="mt-5">
                    <Button
                      className="p-0 shadow-none hover:shadow-none text-base font-redhat-semibold text-black underline normal-case bg-transparent"
                      onClick={() => setAdvancedOptions(!advancedOptions)}
                    >
                      Advanced options
                    </Button>

                    <Link
                      to="/trade"
                      className="flex items-center gap-1 mt-5 text-black hover:text-logoGreen transition-all duration-300 ease-in-out"
                    >
                      <GoArrowLeft className="w-5 h-5" />
                      <p className="text-sm font-redhat-semibold">Go back</p>
                    </Link>
                  </div>
                </>
              ) : (
                <>
                  <div className="mt-5">
                    <Button
                      className="p-0 shadow-none hover:shadow-none text-base font-redhat-semibold text-black underline normal-case bg-transparent"
                      onClick={() => setAdvancedOptions(!advancedOptions)}
                    >
                      Advanced options
                    </Button>

                    <Link
                      to="/trade"
                      className="flex items-center gap-1 mt-5 text-black hover:text-logoGreen transition-all duration-300 ease-in-out"
                    >
                      <GoArrowLeft className="w-5 h-5" />
                      <p className="text-sm font-redhat-semibold">Go back</p>
                    </Link>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="col-span-1">
            <div className="w-full">
              {/* HEADING */}
              <div className="mt-0">
                <p className="text-lg font-redhat-extrabold text-black">
                  2. Select the account and amount of shares
                </p>
              </div>

              {/* ACCOUNTS LIST */}
              <div className="mt-5">
                {accountsCustomList?.map((data, index) => (
                  <div className="mt-5 first:mt-0" key={index}>
                    <p className="text-base font-redhat-bold text-black">
                      {data.broker}
                    </p>
                    {data?.accounts?.map((item, index) => (
                      <div className="mt-2 first:mt-0">
                        <Accordion
                          open={
                            item?.quantity > 0 ||
                            accountAccordion === item?.accountId
                          }
                          animate={CUSTOM_ANIMATION}
                          className={
                            item?.quantity > 0 ||
                            accountAccordion === item?.accountId
                              ? "border-2 border-green-600 rounded-md"
                              : "border border-inputBox rounded-md"
                          }
                        >
                          <AccordionHeader
                            className="w-full bg-white p-3 rounded-md flex items-center justify-between trade-accordion border-b-0"
                            onClick={() => handleOpen(item?.accountId)}
                          >
                            <div className="flex items-center gap-3">
                              <img
                                src={
                                  data.broker === "ALPACA" ||
                                  data.broker === "ALPACA_PAPER"
                                    ? Alpaca
                                    : data.broker === "TRADIER"
                                    ? Tradier
                                    : data.broker === "ROBINHOOD"
                                    ? Robinhood
                                    : data.broker === "WEBULL"
                                    ? Webull
                                    : data.broker === "TRADESTATION_SIM" ||
                                      data.broker === "TRADESTATION"
                                    ? TradeStation
                                    : null
                                }
                                className="w-10 h-10 rounded"
                                alt={data.broker}
                              />
                              <div className="p-0">
                                <h5 className="text-base font-redhat-semibold text-black">
                                  {data?.broker} # ---
                                  {item?.accountId?.split("").slice(-4)}
                                </h5>
                                <p className="text-sm font-redhat-semibold text-greyText mt-1">
                                  {SET_FORMAT(item?.cash ? item?.cash : 0.0)}{" "}
                                  available to trade {symbolDetails?.underlying}
                                </p>
                              </div>
                            </div>
                            <div className="">
                              <h5 className="text-base font-redhat-bold text-black">
                                {SET_FORMAT(item?.cash ? item?.cash : 0.0)}
                              </h5>
                              {item?.quantity > 0 ||
                              accountAccordion === item?.accountId ? (
                                <IoIosArrowUp className="w-5 h-5 text-greyText mt-1 ml-auto" />
                              ) : (
                                <IoIosArrowDown className="w-5 h-5 text-greyText mt-1 ml-auto" />
                              )}
                            </div>
                          </AccordionHeader>
                          <AccordionBody className="bg-white p-3 border-t border-inputBox rounded-b-md">
                            <div className="flex items-center gap-2">
                              <p className="text-sm font-redhat-semibold text-black">
                                Quantity of shares
                              </p>
                              <input
                                name="sharesQty"
                                id="sharesQty"
                                type="number"
                                placeholder="Quantity"
                                value={item?.quantity}
                                onChange={(e) => {
                                  handleQuantityChange(item, e?.target?.value);
                                }}
                                min={0}
                                className="w-1/3 block bg-white border border-inputBox px-4 py-2 rounded-md outline-none focus:outline-none text-sm font-redhat-semibold text-black placeholder:font-redhat-regular"
                              />
                            </div>
                            <div className="mt-2">
                              <p className="text-sm font-redhat-semibold text-black">
                                Estimated order value:{" "}
                                <span className="font-redhat-extrabold">
                                  {orderType === "SHORT" &&
                                    item?.quantity > 0 &&
                                    "-"}
                                  {SET_FORMAT(
                                    item?.quantity
                                      ? parseFloat(
                                          item?.quantity * symbolDetails?.price
                                        ).toFixed(2)
                                      : 0
                                  )}
                                </span>
                              </p>
                            </div>
                          </AccordionBody>
                        </Accordion>
                      </div>
                    ))}
                  </div>
                ))}

                {/* OPTIONS */}
                <Button
                  type="submit"
                  disabled={loading}
                  className="mt-5 w-full shadow-none hover:shadow-none bg-logoGreen hover:bg-opacity-80 rounded-md text-white font-redhat-bold text-sm px-5 py-3 normal-case transition-all duration-300 ease-in-out"
                  onClick={() => handleSubmit()}
                >
                  {loading ? "Please wait.." : "Place Trades"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
export default CreateOrderComponent;
