/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Select from "react-select";
import ReactDatePicker from "react-datepicker";

/* MATERIAL TAILWIND COMPONENTS */
import {
  Button,
  Accordion,
  AccordionHeader,
  AccordionBody,
  Dialog,
  DialogBody,
} from "@material-tailwind/react";

/* ICONS */
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { CgArrowsExchangeAltV } from "react-icons/cg";

/* ASSETS */
import Alpaca from "assets/images/logos/alpaca.png";
import Robinhood from "assets/images/logos/robinhood.png";

import Tradier from "assets/images/logos/tradier.png";
import TradeStation from "assets/images/logos/trade-station.png";
import Webull from "assets/images/logos/webull.png";

/* API IMPORTS */
import {
  getAutomationInformationApi,
  userStrategySetApi,
} from "../../service/api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import { SET_FORMAT } from "config";

/* CONTRIBUTION OPTIONS */
const contributionOptions = [
  // { value: "Per week", label: "Per week", isDisabled: true },
  { value: "Monthly", label: "Monthly" },
  {
    value: "Bi-weekly (Coming Soon)",
    label: "Bi-weekly (Coming Soon)",
    isDisabled: true,
  },
];

const selectedColorOptions = [
  { id: 0, borderColor: "#5B99F7", backgroundColor: "#D7E7FF" },
  { id: 1, borderColor: "#F75B5B", backgroundColor: "#FFD6D6" },
  { id: 2, borderColor: "#159800", backgroundColor: "#EDFFEB" },
  { id: 3, borderColor: "#A0AA27", backgroundColor: "#FFFCB9" },
  { id: 4, borderColor: "#F30000", backgroundColor: "#FDE1C8" },
  { id: 5, borderColor: "#0AA3A3", backgroundColor: "#E3FFFF" },
  { id: 6, borderColor: "#FD00F2", backgroundColor: "#FFE9F9" },
  { id: 7, borderColor: "#7C00C8", backgroundColor: "#F3E9FF" },
];
const CreateAutomationComponent = (props) => {
  const userId = useSelector((state) => state?.user?.userId);
  const brokerAccountsList = useSelector((state) => state?.user?.allAccounts);
  const accountId = useSelector((state) => state?.user?.accountId);
  const allConnectedAccountsList = useSelector(
    (state) => state?.user?.allConnectedAccounts
  );

  const [loading, setLoading] = useState(false);
  const [automationList, setAutomationList] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [showOtherAccounts, setShowOtherAccounts] = useState(false);
  const [meanRiskValue, setMeanRiskValue] = useState(0);
  const [noETFDialog, setNoETFDialog] = useState(false);
  const [noEFTStep, setNoETFStep] = useState(1);

  const [errorMessage, setErrorMessage] = useState(false);

  const [newBrokerages, setNewBrokerages] = useState(false);

  const [selectedAccountId, setSelectedAccountId] = useState(null);

  const handleAccordionStateChange = (id) => {
    setErrorMessage("");
    let currentState = automationList.find((item) => item?.id === id);
    setAutomationList((prevItems) =>
      prevItems.map((item) =>
        item.id === id ? { ...item, state: !currentState?.state } : item
      )
    );
  };

  const isDateExcluded = (date) => {
    const day = date.getDate();
    return day === 29 || day === 30 || day === 31;
  };

  const handleSelectedStateChange = (id) => {
    setErrorMessage("");
    let currentState = automationList.find((item) => item?.id === id);
    setAutomationList((prevItems) =>
      prevItems.map((item) =>
        item.id === id ? { ...item, selected: !currentState?.selected } : item
      )
    );
  };

  /* ACCOUNT OPTIONS */
  const AccountOptions = (props) => {
    return (
      <div
        className={`bg-white px-3 py-2 ${
          props.firstList
            ? "rounded-t-md rounded-b-none border-b border-greyBorder"
            : "rounded-md border-b border-greyBorder"
        } ${
          props.secondList ? "last:rounded-b-md rounded-none" : "rounded-md"
        }`}
      >
        <div className="flex items-center justify-between">
          <div className="p-0">
            <div className="flex items-center gap-3">
              <img
                src={props.img}
                className="w-10 h-10 object-contain rounded"
                alt={props.name}
              />
              <div className="p-0">
                <h5 className="text-base xl:text-lg lg:text-lg font-redhat-semibold text-black">
                  {props.brokerName}
                </h5>
                <p className="text-sm xl:text-base lg:text-base font-redhat-semibold text-greyText">
                  Balance: {SET_FORMAT(props.balance)}
                </p>
              </div>
            </div>
            <div className="mt-2">
              <p className="text-base xl:text-lg lg:text-lg font-redhat-regular text-black">
                You have{" "}
                <span className="font-redhat-bold">
                  {SET_FORMAT(props.investmentBalance)}
                </span>{" "}
                in cash to invest in this account.
              </p>
            </div>
          </div>
          <CgArrowsExchangeAltV className="w-8 h-8 text-greyText" />
        </div>
      </div>
    );
  };

  const handleGetAutomationInformationAllFunction = () => {
    getAutomationInformationApi().then((res) => {
      let tempArrayList = [];
      res?.data?.securities?.forEach((element, index) => {
        tempArrayList.push({
          ...element,
          id: index,
          state: false,
          selected: false,
          investingAmount: null,
          error: false,
          startDate: new Date(),
        });
      });
      setAutomationList(tempArrayList);
    });
  };

  const handleAmountChange = (id, value) => {
    setErrorMessage("");
    setAutomationList((prevItems) =>
      prevItems.map((item) =>
        item.id === id
          ? { ...item, investingAmount: value, error: false }
          : item
      )
    );
  };

  const calculateMeanValue = () => {
    let filteredList = automationList?.filter((el) => el?.selected === true);

    if (filteredList?.length > 0) {
      const extractedValues = filteredList.map((obj) => obj.risk_score);

      const sumValues = extractedValues.reduce((acc, val) => acc + val, 0);
      const numElements = extractedValues.length;

      // Calculate the mean (average)
      const meanValue = sumValues / numElements;

      setMeanRiskValue(meanValue?.toFixed(2));
    } else {
      setMeanRiskValue(0);
    }
  };

  const handleSubmitForm = () => {
    automationList?.forEach((element) => {
      if (
        element?.selected === true &&
        (element?.investingAmount === null || element?.investingAmount === 0)
      ) {
        setAutomationList((prevItems) =>
          prevItems.map((item) =>
            item.id === element?.id ? { ...item, error: true } : item
          )
        );
      }
    });

    let tempArray = [];
    automationList?.forEach((element) => {
      if (
        element?.selected === true &&
        (element?.investingAmount !== null || element?.investingAmount !== 0)
      ) {
        tempArray.push({
          accountId: selectedAccountId,
          underlying: element?.underlying,
          amount: parseFloat(element?.investingAmount),
          strategy: "monthly-" + moment(startDate).format("DD"),
        });
      }
    });

    if (automationList?.filter((el) => el?.selected === true)?.length === 0) {
      toast.error("Please select any one ticker");
      setErrorMessage("Please select any one ticker");
    } else {
      let cumulativeCashRequired = 0;
      automationList
        ?.filter((el) => el?.selected === true)
        ?.forEach((el) => {
          cumulativeCashRequired += parseFloat(el?.investingAmount);
        });

      if (
        allConnectedAccountsList?.accounts?.find(
          (el) => el?.accountId === selectedAccountId
        )?.cash < cumulativeCashRequired
      ) {
        setErrorMessage(
          "Min. Balance of " +
            SET_FORMAT(cumulativeCashRequired) +
            " required to start automation"
        );
      } else {
        if (
          automationList?.filter(
            (el) => el?.selected === true && el?.error === true
          ).length === 0
        ) {
          setLoading(true);
          userStrategySetApi(userId, { add: tempArray, delete: [] })
            .then((res) => {
              toast.success(res?.message);
              props.setAutomationStatus(true);
              props.setAutomationDialog(false);
            })
            .catch((er) => {
              console.log("Error: ", er);
            })
            .finally(() => {
              setLoading(false);
              props.getAutomationsList(props?.automationAccountSelected);
            });
        } else {
          setErrorMessage("Please select any one ticker");
          toast.error("Please select any one ticker");
        }
      }
    }
  };

  const handleReview = () => {
    let finalContent = "";
    if (automationList?.filter((el) => el?.selected === true)?.length > 0) {
      automationList
        ?.filter((el) => el?.selected === true)
        ?.forEach((element, index) => {
          if (index === 0) {
            finalContent += `$${
              element?.investingAmount ? element?.investingAmount : 0
            } into the ${element?.company}`;
          } else if (
            index + 1 ===
            automationList?.filter((el) => el?.selected === true)?.length
          ) {
            finalContent += ` and $${
              element?.investingAmount ? element?.investingAmount : 0
            } into the ${element?.company}`;
          } else {
            finalContent += `, $${
              element?.investingAmount ? element?.investingAmount : 0
            } into the ${element?.company}`;
          }
        });
    }
    return finalContent;
  };

  /* USEEFFECTS */
  useEffect(() => {
    handleGetAutomationInformationAllFunction();
  }, []);

  useEffect(() => {
    calculateMeanValue();
  }, [automationList]);

  useEffect(() => {
    setSelectedAccountId(brokerAccountsList?.accounts?.[0]?.accountId);
  }, [accountId]);

  return (
    <>
      <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5">
        <div className="col-span-1">
          {/* HEADING */}
          <div className="p-0">
            <h2 className="text-2xl font-redhat-bold text-white">
              Create your automation
            </h2>
            <p className="text-lg font-redhat-semibold text-white">
              {/* Select upto three ETFs for automated investments. */}
            </p>
          </div>

          {/* INVESTMENT OPTIONS */}
          <div className="mt-5">
            <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-3">
              {automationList?.map((element, index) => {
                return (
                  <div className="col-span-1">
                    <Accordion
                      open={
                        automationList?.find((el) => el?.id === element?.id)
                          ?.state
                      }
                      icon={
                        automationList?.find((el) => el?.id === element?.id)
                          ?.state ? (
                          <IoIosArrowUp className="w-5 h-5 text-greyText" />
                        ) : (
                          <IoIosArrowDown className="w-5 h-5 text-greyText" />
                        )
                      }
                      className={`rounded-md border ${
                        automationList?.find((el) => el?.id === element?.id)
                          ?.state
                          ? `border-[${
                              selectedColorOptions[
                                index % selectedColorOptions.length
                              ].borderColor
                            }]`
                          : "border-inputBox"
                      }`}
                    >
                      <AccordionHeader
                        className={`px-5 py-3 ${
                          automationList?.find((el) => el?.id === element?.id)
                            ?.state
                            ? "rounded-t-md"
                            : "rounded-md"
                        } border-b-0 text-base font-redhat-semibold text-black transition-all duration-300 ease-in-out`}
                        onClick={() => handleAccordionStateChange(element?.id)}
                        style={{
                          backgroundColor: ` ${
                            automationList?.find((el) => el?.id === element?.id)
                              ?.selected
                              ? `${
                                  selectedColorOptions[
                                    index % selectedColorOptions.length
                                  ].backgroundColor
                                }`
                              : "white"
                          }`,
                        }}
                      >
                        {element?.company}
                      </AccordionHeader>
                      <AccordionBody
                        className={`px-5 py-3 ${
                          automationList?.find((el) => el?.id === element?.id)
                            ?.selected
                            ? `bg-[${
                                selectedColorOptions[
                                  index % selectedColorOptions.length
                                ].backgroundColor
                              }] border-t border-[${
                                selectedColorOptions[
                                  index % selectedColorOptions.length
                                ].borderColor
                              }]`
                            : "bg-white border-t border-greyBorder"
                        } rounded-b-md transition-all duration-300 ease-in-out`}
                        style={{
                          backgroundColor: `${
                            automationList?.find((el) => el?.id === element?.id)
                              ?.selected
                              ? `${
                                  selectedColorOptions[
                                    index % selectedColorOptions.length
                                  ].backgroundColor
                                }`
                              : "white"
                          }`,
                          borderTop: `${
                            automationList?.find((el) => el?.id === element?.id)
                              ?.selected
                              ? `1px solid ${
                                  selectedColorOptions[
                                    index % selectedColorOptions.length
                                  ].borderColor
                                }`
                              : "1px solid #C3C3C3"
                          }`,
                        }}
                      >
                        <p className="text-base font-redhat-semibold text-black">
                          {element?.Description}
                        </p>
                        <p className="mt-2 text-base font-redhat-bold text-black">
                          Risk Score: {element?.risk_score} / 5
                        </p>
                        <Button
                          className={`mt-3 shadow-none hover:shadow-none ${
                            automationList?.find((el) => el?.id === element?.id)
                              ?.selected
                              ? "bg-black bg-opacity-20 text-black"
                              : "bg-black text-white"
                          }  rounded-md normal-case w-full py-3 text-base hover:bg-opacity-80 hover:text-white transition-all duration-300 ease-in-out font-redhat-semibold`}
                          onClick={() => handleSelectedStateChange(element?.id)}
                        >
                          {automationList?.find((el) => el?.id === element?.id)
                            ?.selected
                            ? "Undo select"
                            : "Select"}
                        </Button>
                      </AccordionBody>
                    </Accordion>
                  </div>
                );
              })}
            </div>
          </div>

          {/* EFT NOT FOUND */}
          <div className="mt-5">
            <p
              className="text-base font-redhat-regular text-white text-opacity-90 underline cursor-pointer"
              onClick={() => setNoETFDialog(!noETFDialog)}
            >
              Don't see an ETF you're looking for?
            </p>
          </div>
        </div>
        <div className="col-span-1">
          <div className="bg-[#1F2F3C] rounded-lg p-5">
            {/* INVESTED OPTIONS LIST */}
            {automationList?.filter((e) => e?.selected).length > 0 && (
              <div className="p-0">
                {automationList
                  ?.filter((el) => el?.selected === true)
                  ?.map((element, index) => {
                    return (
                      <div className="block xl:flex lg:flex items-center gap-5">
                        <div className="w-full xl:w-3/5 lg:w-3/5">
                          <div className="form-group">
                            <label className="text-lg font-redhat-semibold text-white">
                              What will you invest in?
                            </label>
                          </div>
                          <div
                            className={`rounded-md px-4 py-3 mt-1`}
                            style={{
                              backgroundColor: `${
                                selectedColorOptions[
                                  element?.id % selectedColorOptions.length
                                ].backgroundColor
                              }`,
                            }}
                          >
                            <div className="flex items-center justify-between">
                              <p className="text-base font-redhat-bold text-black">
                                {element?.company}
                              </p>
                              <Button className="shadow-none hover:shadow-none p-1 bg-transparent hover:bg-black hover:bg-opacity-10 rounded-full transition-all duration-300 ease-in-out">
                                <IoClose className="w-4 h-4 text-greyText" />
                              </Button>
                            </div>
                          </div>
                        </div>
                        <div className="w-full xl:w-2/5 lg:w-2/5 mt-3 xl:mt-0 lg:mt-0">
                          <div className="form-group">
                            <label className="text-lg font-redhat-semibold text-white">
                              How much will you invest?
                            </label>
                            <div className="bg-white rounded-md mt-1">
                              <input
                                type="number"
                                name="amount"
                                id="amount"
                                placeholder="Enter amount"
                                value={element?.investingAmount}
                                onChange={(e) => {
                                  handleAmountChange(
                                    element?.id,
                                    e?.target?.value
                                  );
                                }}
                                className={
                                  element?.error
                                    ? "w-full block outline-none focus:outline-none font-redhat-semibold text-base px-4 py-3 rounded-md bg-red-50 placeholder:text-red-500"
                                    : "w-full block outline-none focus:outline-none font-redhat-semibold text-base px-4 py-3 rounded-md"
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                <p className="mt-2 text-lg font-redhat-semibold text-white">
                  Weighted Risk Score: {meanRiskValue ? meanRiskValue : 0} / 5 (
                  {meanRiskValue
                    ? meanRiskValue > 3.5
                      ? "High"
                      : meanRiskValue <= 3.5 && meanRiskValue >= 2
                      ? "Medium"
                      : "Low"
                    : "Low"}
                  )
                </p>
              </div>
            )}

            {/* CONTRIBUTION DURATION */}
            <div
              className={`w-full xl:w-1/2 lg:w-1/2 ${
                automationList?.filter((e) => e?.selected).length > 0
                  ? "mt-8"
                  : "mt-0"
              }`}
            >
              <div className="form-group">
                <label className="text-lg font-redhat-semibold text-white">
                  How often will you contribute?
                </label>
                <div className="mt-1">
                  <Select
                    options={contributionOptions}
                    classNamePrefix="order"
                    placeholder="Select contribution"
                    className="react-select"
                  />
                </div>
              </div>
              <div className="form-group mt-5">
                <div className="flex items-center gap-1`">
                  <div className="h-full bg-white px-4 py-3 rounded-md rounded-r-none border-r border-greyBorder">
                    <p className="text-base font-redhat-semibold text-black flex-shrink-0">
                      Starting
                    </p>
                  </div>
                  <div className="w-full">
                    <ReactDatePicker
                      className="w-full rounded-md px-4 py-3 font-redhat-semibold rounded-l-none"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      placeholderText="Starting"
                      minDate={moment().toDate()}
                      excludeDates={[new Date()]}
                      filterDate={(date) => !isDateExcluded(date)}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* WHICH ACCOUNT WILL MAKE THE INVESTMENT */}
            <div className="mt-8">
              <div className="form-group">
                <label className="text-lg font-redhat-semibold text-white">
                  Which account will make the investment?
                </label>
                <div className="mt-1 relative">
                  <div
                    onClick={() => {
                      setShowOtherAccounts(!showOtherAccounts);
                    }}
                  >
                    <AccountOptions
                      img={
                        brokerAccountsList?.accounts?.find(
                          (el) => el?.accountId === selectedAccountId
                        )?.brokerage === "ALPACA" ||
                        brokerAccountsList?.accounts?.find(
                          (el) => el?.accountId === selectedAccountId
                        )?.brokerage === "ALPACA_PAPER"
                          ? Alpaca
                          : brokerAccountsList?.accounts?.find(
                              (el) => el?.accountId === selectedAccountId
                            )?.brokerage === "TRADIER"
                          ? Tradier
                          : brokerAccountsList?.accounts?.find(
                              (el) => el?.accountId === selectedAccountId
                            )?.brokerage === "ROBINHOOD"
                          ? Robinhood
                          : brokerAccountsList?.accounts?.find(
                              (el) => el?.accountId === selectedAccountId
                            )?.brokerage === "WEBULL"
                          ? Webull
                          : brokerAccountsList?.accounts?.find(
                              (el) => el?.accountId === selectedAccountId
                            )?.brokerage === "TRADESTATION_SIM" ||
                            brokerAccountsList?.accounts?.find(
                              (el) => el?.accountId === selectedAccountId
                            )?.brokerage === "TRADESTATION"
                          ? TradeStation
                          : null
                      }
                      name={
                        brokerAccountsList?.accounts?.find(
                          (el) => el?.accountId === selectedAccountId
                        )?.brokerage
                      }
                      brokerName={
                        brokerAccountsList?.accounts?.find(
                          (el) => el?.accountId === selectedAccountId
                        )?.brokerage +
                        " # --" +
                        selectedAccountId?.slice(-4)
                      }
                      balance={
                        allConnectedAccountsList?.accounts?.find(
                          (el) => el?.accountId === selectedAccountId
                        )?.cash
                          ? allConnectedAccountsList?.accounts?.find(
                              (el) => el?.accountId === selectedAccountId
                            )?.cash
                          : 0.0
                      }
                      investmentBalance={
                        allConnectedAccountsList?.accounts?.find(
                          (el) => el?.accountId === selectedAccountId
                        )?.cash
                          ? allConnectedAccountsList?.accounts?.find(
                              (el) => el?.accountId === selectedAccountId
                            )?.cash
                          : 0.0
                      }
                      firstList={showOtherAccounts}
                    />
                  </div>

                  <div
                    className={
                      showOtherAccounts ? "block first:rounded-b-md" : "hidden"
                    }
                  >
                    {allConnectedAccountsList?.accounts
                      ?.filter((el) => el?.accountId !== selectedAccountId)
                      ?.map((element, index) => {
                        return (
                          <div
                            onClick={() => {
                              setShowOtherAccounts(!showOtherAccounts);
                              setSelectedAccountId(element?.accountId);
                              setErrorMessage("");
                            }}
                          >
                            <AccountOptions
                              img={
                                brokerAccountsList?.accounts?.find(
                                  (el) => el?.accountId === element?.accountId
                                )?.brokerage === "ALPACA" ||
                                brokerAccountsList?.accounts?.find(
                                  (el) => el?.accountId === element?.accountId
                                )?.brokerage === "ALPACA_PAPER"
                                  ? Alpaca
                                  : brokerAccountsList?.accounts?.find(
                                      (el) =>
                                        el?.accountId === element?.accountId
                                    )?.brokerage === "TRADIER"
                                  ? Tradier
                                  : brokerAccountsList?.accounts?.find(
                                      (el) =>
                                        el?.accountId === element?.accountId
                                    )?.brokerage === "ROBINHOOD"
                                  ? Robinhood
                                  : brokerAccountsList?.accounts?.find(
                                      (el) =>
                                        el?.accountId === element?.accountId
                                    )?.brokerage === "WEBULL"
                                  ? Webull
                                  : brokerAccountsList?.accounts?.find(
                                      (el) =>
                                        el?.accountId === element?.accountId
                                    )?.brokerage === "TRADESTATION_SIM" ||
                                    brokerAccountsList?.accounts?.find(
                                      (el) =>
                                        el?.accountId === element?.accountId
                                    )?.brokerage === "TRADESTATION"
                                  ? TradeStation
                                  : null
                              }
                              name={
                                brokerAccountsList?.accounts?.find(
                                  (el) => el?.accountId === element?.accountId
                                )?.brokerage
                              }
                              brokerName={
                                brokerAccountsList?.accounts?.find(
                                  (el) => el?.accountId === element?.accountId
                                )?.brokerage +
                                " # --" +
                                element?.accountId?.slice(-4)
                              }
                              balance={
                                allConnectedAccountsList?.accounts?.find(
                                  (el) => el?.accountId === element?.accountId
                                )?.cash
                                  ? allConnectedAccountsList?.accounts?.find(
                                      (el) =>
                                        el?.accountId === element?.accountId
                                    )?.cash
                                  : 0.0
                              }
                              investmentBalance={
                                allConnectedAccountsList?.accounts?.find(
                                  (el) => el?.accountId === element?.accountId
                                )?.cash
                                  ? allConnectedAccountsList?.accounts?.find(
                                      (el) =>
                                        el?.accountId === element?.accountId
                                    )?.cash
                                  : 0.0
                              }
                              secondList={showOtherAccounts}
                            />
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>

            {/* AUTOMATION REVIEW */}
            <div className="mt-8">
              <div className="form-group">
                <label className="text-lg font-redhat-semibold text-white">
                  Automation review
                </label>
                <div className="mt-1">
                  <div className="w-full bg-[#001422] rounded-md p-3">
                    <p className="text-base font-redhat-semibold text-white">
                      Every month on {moment(startDate).format("Do")}, you’ll
                      invest {handleReview()} in your{" "}
                      {
                        brokerAccountsList?.accounts?.find(
                          (el) => el?.accountId === selectedAccountId
                        )?.brokerage
                      }{" "}
                      account ending in
                      {" # --" + selectedAccountId?.slice(-4)}.
                    </p>
                    <p className="mt-2 text-base font-redhat-semibold text-white">
                      The first investment will be made on{" "}
                      {moment(startDate).format("M/D/Y")}.
                    </p>
                    <p className="mt-2 text-base font-redhat-semibold text-white">
                      You can pause and edit this strategy at any time.{" "}
                      <a
                        target="_blank"
                        className=" underline"
                        href="https://resources.scaletrade.ai/scaletrade-terminal/pause-and-edit-strategy-details"
                        rel="noreferrer"
                      >
                        Learn more.
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {errorMessage && (
              <p className="text-base font-redhat-semibold mt-6 text-red-500">
                {errorMessage}
              </p>
            )}
            {/* START AUTOMATION */}
            <Button
              className="w-full mt-2 shadow-none hover:shadow-none bg-gradient normal-case text-white font-redhat-semibold text-lg"
              disabled={loading}
              onClick={() => {
                // setAutomationStatus(!automationStatus);
                // setAutomationDialog(!automationDialog);
                // setAutomations(!automations);
                handleSubmitForm();
              }}
            >
              {loading ? "Please wait..." : "Start Automated Investment"}
            </Button>
          </div>
        </div>
      </div>

      <div className="absolute top-1 right-1">
        <Button
          className="shadow-none hover:shadow-none rounded-full bg-transparent hover:bg-white hover:bg-opacity-10 p-2 transition-all duration-300 ease-in-out"
          onClick={() => props.setAutomationDialog(false)}
        >
          <IoClose className="w-5 h-5 text-white" />
        </Button>
      </div>

      {/* DON'T SEE ETF */}
      <Dialog
        open={noETFDialog}
        handler={() => setNoETFDialog(!noETFDialog)}
        size="xs"
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        className="bg-[#1C1C1C] overflow-visible"
      >
        <DialogBody className="px-5 relative">
          <div className="py-5 border-b border-inputBox border-opacity-30">
            <img
              src={require("../../assets/images/scaletrade-logo.png")}
              className="h-10 w-auto mx-auto"
              alt="ScaleTrade"
            />
          </div>
          <div className="py-5">
            {noEFTStep === 1 && (
              <>
                <h5 className="text-lg font-redhat-bold text-white">
                  We add new ETF's all the time
                </h5>
                <p className="text-base font-redhat-semibold text-white mt-1">
                  Please tell us which ETF you're looking for, and we will
                  notify you when it is added to ScaleTrade.
                </p>
                <form className="mt-3">
                  <input
                    type="text"
                    name="etfName"
                    id="etfName"
                    placeholder="Enter the ETF.."
                    className="block w-full bg-white border border-inputBox outline-none focus:outline-none text-base font-redhat-semibold text-black px-5 py-3 rounded-md"
                  />
                  <Button
                    className="mt-5 w-full shadow-none hover:shadow-none text-base font-redhat-semibold text-white bg-gradient normal-case"
                    onClick={() => setNoETFStep(2)}
                  >
                    Submit and notify me when it's added
                  </Button>
                </form>
              </>
            )}

            {noEFTStep === 2 && (
              <>
                <h5 className="text-lg font-redhat-bold text-white">
                  Your request has been submitted!
                </h5>
                <p className="text-base font-redhat-semibold text-white mt-1">
                  We will notify you via email when this ETF is added to
                  ScaleTrade.
                </p>
                <Button
                  className="mt-5 w-full shadow-none hover:shadow-none text-base font-redhat-semibold text-white bg-gradient normal-case"
                  onClick={() => setNoETFDialog(!noETFDialog)}
                >
                  Done
                </Button>
              </>
            )}
          </div>

          <div className="absolute top-2 right-2">
            <Button
              className="shadow-none hover:shadow-none bg-transparent rounded-full p-2 hover:bg-white hover:bg-opacity-10 transition-all duration-300 ease-in-out"
              onClick={() => setNoETFDialog(!noETFDialog)}
            >
              <IoClose className="w-4 h-4 text-white text-opacity-50s" />
            </Button>
          </div>
        </DialogBody>
      </Dialog>

      {/* NEW BROEKRAGES */}
      <Dialog
        open={newBrokerages}
        handler={() => setNewBrokerages(!newBrokerages)}
        size="xs"
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        className="bg-[#1C1C1C] overflow-visible"
      >
        <DialogBody className="px-5 relative">
          <div className="py-5 border-b border-inputBox border-opacity-30">
            <img
              src={require("../../assets/images/scaletrade-logo.png")}
              className="h-10 w-auto mx-auto"
              alt="ScaleTrade"
            />
          </div>
          <div className="py-5">
            {noEFTStep === 1 && (
              <>
                <h5 className="text-lg font-redhat-bold text-white">
                  We add new brokers all the time
                </h5>
                <p className="text-base font-redhat-semibold text-white mt-1">
                  Please tell us which broker you're looking for, and we will
                  notify you when it is added to ScaleTrade.
                </p>
                <form className="mt-3">
                  <input
                    type="text"
                    name="etfName"
                    id="etfName"
                    placeholder="Enter the broker name"
                    className="block w-full bg-white border border-inputBox outline-none focus:outline-none text-base font-redhat-semibold text-black px-5 py-3 rounded-md"
                  />
                  <Button
                    className="mt-5 w-full shadow-none hover:shadow-none text-base font-redhat-semibold text-white bg-gradient normal-case"
                    onClick={() => setNoETFStep(2)}
                  >
                    Submit and notify me when it's added
                  </Button>
                </form>
              </>
            )}

            {noEFTStep === 2 && (
              <>
                <h5 className="text-lg font-redhat-bold text-white">
                  Your request has been submitted!
                </h5>
                <p className="text-base font-redhat-semibold text-white mt-1">
                  We will notify you via email when this broker is added to
                  ScaleTrade.
                </p>
                <Button
                  className="mt-5 w-full shadow-none hover:shadow-none text-base font-redhat-semibold text-white bg-gradient normal-case"
                  onClick={() => setNoETFDialog(!noETFDialog)}
                >
                  Done
                </Button>
              </>
            )}
          </div>

          <div className="absolute top-2 right-2">
            <Button
              className="shadow-none hover:shadow-none bg-transparent rounded-full p-2 hover:bg-white hover:bg-opacity-10 transition-all duration-300 ease-in-out"
              onClick={() => setNoETFDialog(!noETFDialog)}
            >
              <IoClose className="w-4 h-4 text-white text-opacity-50s" />
            </Button>
          </div>
        </DialogBody>
      </Dialog>
    </>
  );
};

export default CreateAutomationComponent;
